import { Form, Input } from "antd";
import { Ticket } from "utils/helpers/static_data";
import { filters } from "components/filters/ticketsFillters";
import { FormInstance } from "antd/lib/form";
import { t } from "i18next";
import { NumericInput } from "components/inputs/NumericInput";

export const Costs = ({
  Status,
  disabled = false,
  form,
}: {
  Status: filters["Status"];
  disabled: boolean;
  form: FormInstance;
}) => {
  return (
    <Form.Item
      label={<>{t("transportation_cost")}</>}
      name="transportation_cost"
      rules={[
        {
          required:
            Status !== Ticket.create &&
            Status !== Ticket.priced &&
            Status !== Ticket.pending,
          message: t("please_input_transportation_cost"),
        },
        {
          validator: (_, value) => {
            if (value && parseInt(value) < 0)
              return Promise.reject(
                new Error("Must be greater than or equal to 0!")
              );
            return Promise.resolve();
          },
        },
      ]}
    >
      <NumericInput
        type="text"
        style={{ direction: "ltr" }}
        disabled={
          Status === Ticket.create ||
          Status === Ticket.pending ||
          Status === Ticket.priced ||
          disabled
        }
      />
    </Form.Item>
  );
};

import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import i18next from "i18next";
import Color from "utils/helpers/export-sass-colors";

interface Props {
  /**
   * content of the hints
   */
  content: any;
  children: any;
  disabled?: boolean;
  /**
   * "self" to get hints from the self elements
   */
  target?: "self" | undefined;
}
/**
 * This is a tooltip to add hints for components
 */
const ToolTip = (props: Props) => {
  const content = (
    <>
      <div className="content">{props.content}</div>
    </>
  );
  return (
    <>
      {props.target !== "self" ? props.children : null}
      <Tooltip
        title={content}
        color={Color.white}
        placement={i18next.language === "ar" ? "leftTop" : "rightTop"}
        overlayClassName="toolTip"
        overlayStyle={props.disabled ? { display: "none" } : {}}
      >
        {props.target !== "self" ? (
          <InfoCircleFilled
            style={{ color: Color.gray9, padding: "5px", fontSize: "13px" }}
          />
        ) : (
          props.children
        )}
      </Tooltip>
    </>
  );
};

export default ToolTip;

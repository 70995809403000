import { Form, Select } from "antd";
import { Rule } from "antd/es/form";
import { CategoryModel } from "models/categories";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import { WorkplaceModel } from "models/workplaces";
import { settingInterface } from "DTO/interfaces";
import useWorkplaces from "hooks/query/workplaces/useWorkplaces";
import { t } from "i18next";

const { Option } = Select;

export const WorkPlace = ({
  name,
  required = false,
  disabled = false,
  rules,
  workPlace,
  form,
}: {
  name: string;
  required?: boolean;
  disabled?: boolean;
  rules: Rule[];
  workPlace: WorkplaceModel[] | null | undefined;
  form: FormInstance;
}) => {
  useEffect(() => {
    form.resetFields([name]);
  }, [workPlace]);

  const [workplace, setWorkplace] = useState();
  const { data, isLoading, isError } = useWorkplaces();
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error</div>;
  }

  return (
    <Form.Item
      name={name}
      required={required}
      rules={rules}
      label={<>{t("serving_place")}</>}
    >
      <Select disabled={disabled}>
        {data?.data?.map((item: any) => (
          <Option key={item.id.toString()}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};

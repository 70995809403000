import { transferTicket } from "services/tickets";
import { useMutation, useQueryClient } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

export default function TransferTicket() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return {
    ...useMutation(({ id, data }: any) => transferTicket(id, data), {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم نقل التذكرة"
                : "Ticket has been transfered successfully",
            type: "success",
          });
          navigate("/client-requests");
          queryClient.invalidateQueries("clientRequests");
          queryClient.invalidateQueries("clientRequest");
        }
      },
    }),
  };
}

import { LoadingOutlined } from "@ant-design/icons";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import Color from "utils/helpers/export-sass-colors";
import { MapComponent, MapComponentProps } from "../MapComponent";
import { Result } from "antd";
import { t } from "i18next";

interface MapWrapperProps extends MapComponentProps {}

const MapWrapper: React.FC<MapWrapperProps> = ({
  center = { lat: 33.523698, lng: 36.316379 },
  zoom = 13,
  style,
  onClick,
  ...props
}) => {
  const defaultStyle: React.CSSProperties = {
    direction: "ltr",
    width: "100%",
    minHeight: "50vh",
    border: `2px solid ${Color.blue}`,
    borderRadius: "5px",
  };
  const render = (status: Status) => {
    switch (status) {
      case Status.FAILURE:
        return (
          <div style={defaultStyle}>
            <Result
              status="error"
              title="Error"
              subTitle={t("unexpected_error")}
            />
          </div>
        );
      case Status.SUCCESS:
        return (
          <MapComponent
            center={
              props.initialMarker?.lat !== undefined &&
              props.initialMarker.lng !== undefined
                ? props.initialMarker
                : center
            }
            zoom={zoom}
            style={{
              ...defaultStyle,
              ...style,
            }}
            onClick={onClick}
            {...props}
          />
        );
      default:
        return (
          <div
            style={{
              ...defaultStyle,
              textAlign: "center",
              color: Color.blue,
              display: "grid",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <LoadingOutlined style={{ fontSize: 40 }} spin />
          </div>
        );
    }
  };
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API as string}
      render={render}
    />
  );
};

export default MapWrapper;

/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../container";
import RegisterForm from "./registerForm";
import OTPForm from "../OTP";
import { useAppSelector } from "store";
import { useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";
import { FaArrowRight } from "react-icons/fa";
import BackButton from "components/UI/BackButton";
const { Title } = Typography;
/**
 * register page
 */
const Register = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const [email, setEmail] = useState("");
  const [Status, setStatus] = useState<"register" | "otp">("register");

  useEffect(() => {
    if (isLoggedIn) {
      navigate({ pathname: "/tickets" });
    }
  }, [isLoggedIn]);

  return (
    <>
      <Container>
        <Title
          style={{ textAlign: "center", marginBlock: "20px 30px" }}
          level={5}
        >
          {t("create_account")}
        </Title>

        <BackButton
          title={t("login")}
          onClick={() => navigate("/login")}
          dir={i18next.language === "ar" ? "right" : "left"}
        />
        {Status === "register" && (
          <RegisterForm setStatus={setStatus} setEmail={setEmail} />
        )}
        {Status === "otp" && <OTPForm setStatus={setStatus} email={email} />}
      </Container>
    </>
  );
};

export default Register;

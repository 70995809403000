import { Form, Input } from "antd";
import Modal from "antd/es/modal/Modal";
import { t } from "i18next";
import { CategoryModel } from "models/categories";
import { SetStateAction, useEffect, useState } from "react";

const EditClassificationModal = ({
  open,
  setOpen,
  item,
  isLoading,
  onUpdate,
}: {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  item: CategoryModel | undefined;
  isLoading: boolean;
  onUpdate: ({ data, id }: any) => void;
}) => {
  const [form] = Form.useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const onFinish = (e: any) => {
    const formData = new FormData();

    formData.append("name_ar", e.name_ar);
    formData.append("name_en", e.name_en);
    formData.append("_method", "PUT");

    onUpdate({ id: item?.id, data: formData });
  };

  useEffect(() => {
    form.setFieldValue("name_ar", item?.name_ar);
    form.setFieldValue("name_en", item?.name_en);
  }, [open]);

  return (
    <Modal
      open={open}
      width={400}
      title={t("edit_category")}
      centered
      destroyOnClose
      bodyStyle={{ padding: 20 }}
      onCancel={() => handleClose()}
      onOk={() => {
        form.submit();
      }}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          name_ar: item?.name_ar,
          name_en: item?.name_en,
        }}
        labelCol={{ span: 24 }}
        validateMessages={{
          required: t("please_input") + " ${label}!",
        }}
      >
        <Form.Item
          name={"name_ar"}
          label={t("arabic_name")}
          rules={[{ required: true }]}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"name_en"}
          label={t("english_name")}
          rules={[{ required: true }]}
          required
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditClassificationModal;

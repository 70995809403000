import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { useCategoriesList } from "hooks/query/categories/useCategories";
import useLevels from "hooks/query/levels/useLevels";
import AddService from "hooks/query/services/addService";
import i18next, { t } from "i18next";
import { useEffect, useState } from "react";
interface AddTicketServiceModalProps {
  category?: string;
}
const { Option } = Select;
const AddTicketServiceModal = ({ category }: AddTicketServiceModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isLinked, setIsLinked] = useState<boolean>(false);

  const { data: categories } = useCategoriesList();

  const addService = AddService();

  const { data: levels } = useLevels(1, 8);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, []);

  const handleClose = () => {
    form.setFieldsValue("");
    setIsOpen(false);
  };
  const onFinish = (e: any) => {
    const formData = new FormData();
    formData.append("level_id", e.level_id);
    formData.append("category_id", e.category_id);
    formData.append("name_ar", e.name_ar);
    formData.append("name_en", e.name_en);
    formData.append("is_linked", isLinked.toString());
    formData.append("cost", e.cost !== undefined ? e.cost : 0);
    e.category_id.forEach((category: any) => {
      formData.append("category_id[]", `${category}`);
    });
    addService.mutateAsync({ data: formData }).then(() => {
      handleClose();
      form.resetFields();
    });
  };
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setIsOpen(true);
        }}
        icon={<PlusOutlined />}
      >
        {t("add")}
      </Button>
      <Modal
        key={"addSubCategory"}
        title={t("add_service")}
        open={isOpen}
        width={400}
        centered
        bodyStyle={{ padding: 20, maxHeight: "420px", overflowY: "scroll" }}
        destroyOnClose
        onCancel={() => handleClose()}
        confirmLoading={addService.isLoading}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            level_id: category,
          }}
          labelCol={{ span: 24 }}
          validateMessages={{
            required: t("please_input") + " ${label}!",
          }}
        >
          <Form.Item
            name={"level_id"}
            label={
              i18next.language === "ar" ? "تصنيف المستوى" : "Category level"
            }
            rules={[{ required: true }]}
            required
          >
            <Select>
              {levels?.data.map((level) => (
                <Option key={level.id}>{level.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"category_id"}
            label={t("category_type")}
            rules={[{ required: true }]}
            required
          >
            <Select mode="multiple">
              {categories?.data.map((category) => (
                <Option key={category.id}>{category.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={"name_ar"}
            label={t("arabic_name")}
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"name_en"}
            label={t("english_name")}
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"cost"}
            label={t("cost")}
            // rules={[{ required: true }]}
            // required
          >
            <Input disabled={isLinked} />
          </Form.Item>
          <Form.Item name={"is_linked"}>
            <Checkbox
              checked={isLinked}
              onChange={(e) => setIsLinked(!isLinked)}
            >
              {t("linked_cost_to_level")}
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddTicketServiceModal;

import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Input } from "antd";
import { Roles, Ticket } from "utils/helpers/static_data";
import TicketContactsModal from "components/Modals/ticketContactsModal";
import { useEffect, useState } from "react";
import { ContactModel, UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import InputPhone from "components/inputPhone";
import { TicketModel } from "models/tickets";
import { CountryPhoneInputValue } from "antd-country-phone-input";
import { SplitPhone } from "utils/helpers/split-phone";
import { t } from "i18next";

export const PhoneList = ({
  form,
  required = false,
  disabled = false,
  ticket,
  Status,
  companyPlace,
  CompanyInputs,
  userContact,
}: {
  form: FormInstance;
  required?: boolean;
  disabled?: boolean;
  ticket?: TicketModel;
  Status: any;
  companyPlace: any;
  CompanyInputs: "client" | "company";
  userContact: any;
}) => {
  const [ContactsModalVisible, setContactsModalVisible] =
    useState<boolean>(false);

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  useEffect(() => {
    if (User && User.role_id === Roles.client) {
      if (User.phone_number) {
        form.setFieldValue("contacts", [
          {
            title: User.full_name,
            phone: { ...SplitPhone(User.phone_number) },
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [User]);

  useEffect(() => {
    if (
      !(
        ticket &&
        ticket?.phone?.length !== undefined &&
        ticket.phone.length > 0
      ) ||
      (ticket && ticket.phone.length === 0)
    ) {
      if (userContact) {
        form.setFieldValue("contacts", [userContact]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContact]);

  return (
    <div style={{ maxHeight: "400px", overflowY: "scroll", padding: "1rem" }}>
      <Form.List
        name={"contacts"}
        initialValue={[{ phone: { short: "SY", code: 963 }, title: "" }]}
        rules={[
          {
            validator(_, value) {
              if (required && (!value || value.length < 1))
                return Promise.reject(
                  new Error(t("please_input_contacts_info"))
                );
              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? t("contacts_information") : ""}
                key={field.key}
                required={index === 0}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  noStyle
                >
                  <Form.Item name={[field.name, "title"]}>
                    <Input
                      type={"text"}
                      style={{
                        direction: "rtl",
                      }}
                      placeholder={t("name")}
                      disabled={disabled}
                      autoComplete={"off"}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, "phone"]}
                    rules={[
                      {
                        validator: (_, value: CountryPhoneInputValue) => {
                          if (!value?.phone)
                            return Promise.reject(
                              new Error(t("phonenumber_required"))
                            );
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputPhone
                      className="phoneNumberInputCountry"
                      placeholder="Tel / Mobile"
                      disabled={disabled}
                    />
                  </Form.Item>
                </Form.Item>
                {fields.length > 1 ? (
                  <DeleteFilled
                    className="dynamic-delete-button"
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                ) : null}
              </Form.Item>
            ))}

            {!disabled && (
              <Form.Item>
                <div
                  className="groupBtn"
                  style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}
                >
                  {User?.role_id !== Roles.accountant &&
                    User?.role_id !== Roles.technical && (
                      <Button
                        type="primary"
                        className="finishBtn addPhoneBtn"
                        onClick={() => {
                          add({ phone: { short: "SY", code: 963 } });
                        }}
                        icon={<PlusOutlined />}
                      >
                        {t("add_contact")}
                      </Button>
                    )}
                  {CompanyInputs === "company" &&
                    User?.role_id !== Roles.client &&
                    User?.role_id !== Roles.technical &&
                    User?.role_id !== Roles.accountant && (
                      <>
                        <Button
                          type="primary"
                          className="finishBtn addPhoneBtn"
                          onClick={() => setContactsModalVisible(true)}
                          icon={<PlusOutlined />}
                        >
                          {t("number_exist")}
                        </Button>
                        {ContactsModalVisible && (
                          <TicketContactsModal
                            modal={ContactsModalVisible}
                            close={() => setContactsModalVisible(false)}
                            placeId={companyPlace}
                            phones={form.getFieldValue("contacts")}
                            addContact={(contact: ContactModel) => {
                              let F = true;
                              let count = 0;
                              if (form.getFieldValue("contacts")?.length > 0) {
                                form.getFieldValue("contacts").forEach(
                                  (
                                    {
                                      phone,
                                      title,
                                    }: {
                                      phone: {
                                        short: string;
                                        code: number;
                                        phone: string;
                                      };
                                      title: string;
                                    },
                                    index: any
                                  ) => {
                                    if (
                                      (phone.phone === "" ||
                                        phone.phone === undefined) &&
                                      F
                                    ) {
                                      remove(index);
                                      add(
                                        {
                                          phone: {
                                            ...SplitPhone(contact.value),
                                          },
                                          title: contact.user?.full_name ?? "",
                                        },
                                        index
                                      );
                                      F = false;
                                    }
                                    if (
                                      !(
                                        phone.phone === "" ||
                                        phone.phone === undefined
                                      ) &&
                                      F
                                    ) {
                                      count++;
                                      if (
                                        count ===
                                        form.getFieldValue("contacts").length
                                      ) {
                                        add({
                                          phone: {
                                            ...SplitPhone(contact.value),
                                          },
                                          title: contact.user?.full_name ?? "",
                                        });
                                        F = false;
                                      }
                                    }
                                  }
                                );
                              } else {
                                add({
                                  phone: {
                                    ...SplitPhone(contact.value),
                                  },
                                  title: contact.user?.full_name ?? "",
                                });
                                F = false;
                              }
                            }}
                          />
                        )}
                      </>
                    )}
                </div>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            )}
          </>
        )}
      </Form.List>
      {Status !== Ticket.create && ticket?.old_phone !== null && (
        <Form.Item
          label={t("old_phone_number")}
          initialValue={ticket?.old_phone}
        >
          <div
            style={{
              background: "transparent",
              pointerEvents: "none",
              direction: "ltr",
            }}
            className="oldPhone"
          >
            {ticket?.old_phone}
          </div>
        </Form.Item>
      )}
    </div>
  );
};

import { hasPermission } from "components/permissionsGate";
import TicketInput from "../forms/inputs";
import { Ticket } from "utils/helpers/static_data";
import { useState } from "react";

import { Col, FormInstance } from "antd";
import { t } from "i18next";

interface ServicesInputProps {
  Status: any;
  form: FormInstance<any>;
  category: number;
  setCategory: any;
}

const ServicesInput = ({
  Status,
  form,
  setCategory,
  category,
}: ServicesInputProps) => {
  return (
    <>
      <TicketInput.Category
        name="category"
        disabled={
          !hasPermission([
            Status === Ticket.create ? "add_tickets" : "edit_tickets",
          ])
        }
        rules={[
          {
            required: true,
            message: t("please_select_category_first"),
          },
        ]}
        setCategory={setCategory}
        form={form}
      />
      <TicketInput.Service
        name="service"
        disabled={
          !hasPermission([
            Status === Ticket.create ? "add_tickets" : "edit_tickets",
          ])
        }
        rules={[
          {
            required: true,
            message: t("please_select_service_first"),
          },
        ]}
        form={form}
        category_id={category}
      />
    </>
  );
};

export default ServicesInput;

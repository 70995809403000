import { Form, Input } from "antd";

interface HistorBillsProps {
  name: string;
  readonly: boolean;
}

const BillsHistory = ({ name, readonly }: HistorBillsProps) => {
  return (
    <Form.Item label="التكلفة سابقاً" name={name}>
      <Input readOnly={readonly} dir="auto" />
    </Form.Item>
  );
};

export default BillsHistory;

import { notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Location {
  latitude: number | null;
  longitude: number | null;
  error: string | null;
}

const useCurrentLocation = (): [
  Location,
  (onSuccess: (location: Location) => void) => void
] => {
  const { t } = useTranslation();
  const [location, setLocation] = useState<Location>({
    latitude: null,
    longitude: null,
    error: null,
  });

  const getLocationOnClick = async (
    onSuccess: (location: Location) => void
  ) => {
    const handleSuccess = (position: GeolocationPosition) => {
      const updatedLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        error: null,
      };
      setLocation(updatedLocation);
      onSuccess(updatedLocation);
    };

    const handleError = (error: GeolocationPositionError) => {
      setLocation({
        latitude: null,
        longitude: null,
        error: error.message,
      });
      if (error.code === 1) {
        notification.open({
          type: "warning",
          message: t("please_accept_location_permission"),
          duration: 3,
        });
      } else {
        notification.open({
          type: "warning",
          message: t("please_install_app_to_get_location"),
          duration: 7,
        });
      }
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
        timeout: 30000,
        enableHighAccuracy: true,
        maximumAge: 75000,
      });
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by your browser.",
      });
      notification.open({
        type: "warning",
        message: t("geolocation_not_supported"),
        duration: 7,
      });
    }
  };

  return [location, getLocationOnClick];
};

export default useCurrentLocation;

import { LoadingOutlined } from "@ant-design/icons";
import { Select, Spin } from "antd";

const { Option } = Select;

export const loadingMoreSpinner = (
  <Option disabled={true} style={{ textAlign: "center", cursor: "default" }}>
    <Spin indicator={<LoadingOutlined />} spinning={true} />
  </Option>
);

import useDebounce from "hooks/others/useDebounce";
import { ClientRequestModel } from "models/clientRequests";
import { useQuery } from "react-query";
import { FetchUsersClientRequests } from "services/clientRequests";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";
export default function useUsersClientRequests(
  userId: number,
  page: number,
  search: string
) {
  const { newParam: searchParam } = useDebounce(search);
  const queryKey = ["usersClientRequests", userId, page, searchParam];
  return {
    ...useQuery<ApiResult<ClientRequestModel[]>, ApiError>(
      queryKey,
      () => FetchUsersClientRequests(userId, page, search),
      {
        staleTime: 5000,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    ),
  };
}

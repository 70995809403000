import { hasPermission } from "components/permissionsGate";
import TicketInput from "../forms/inputs";
import { Ticket } from "utils/helpers/static_data";
import { TicketModel } from "models/tickets";
import { FormInstance } from "antd";

interface PhoneListInputProps {
  ticket?: TicketModel;
  Status: any;
  form: FormInstance<any>;
  companyPlace: any;
  CompanyInputs: any;
  userContact: any;
}

const PhoneListInput = ({
  ticket,
  Status,
  form,
  companyPlace,
  CompanyInputs,
  userContact,
}: PhoneListInputProps) => {
  return (
    <TicketInput.PhoneList
      disabled={
        !hasPermission([
          Status === Ticket.create ? "add_tickets" : "edit_tickets",
        ])
      }
      required={true}
      form={form}
      ticket={ticket}
      Status={Status}
      companyPlace={companyPlace}
      CompanyInputs={CompanyInputs}
      userContact={userContact}
    />
  );
};

export default PhoneListInput;

import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";

export const hasPermission = (scopes: any) => {
  const user: UserModel | null = UserData.Decrypt();
  let containsAll: any = false;
  if (user?.permissions) {
    containsAll = scopes?.every((scope: any) => {
      return user?.permissions?.includes(scope);
    });
  }
  return containsAll;
};
export const hasSomePermission = (scopes: any) => {
  const user: UserModel | null = UserData.Decrypt();
  let containsAll: any = false;
  if (user?.permissions) {
    scopes.forEach((scope: any) => {
      if (
        user?.permissions?.find((element: any) => element === scope) !==
        undefined
      )
        containsAll = true;
    });
  }
  return containsAll;
};

/**
 * To use permissions on update all components
 */
export function PermissionsUpdate({
  children,
  /**
   * set spesifc permissions
   */
  scopes = [],
}: any) {
  const permissionGranted = hasPermission(scopes);

  if (!permissionGranted) {
    const disabled = <div className="disabled">{children}</div>;
    const textarea = document.querySelectorAll(".disabled textarea");
    if (textarea.length > 0) {
      for (let i = 0; i < textarea.length; i++) {
        textarea[i].setAttribute("readonly", "true");
      }
    }
    return disabled;
  }

  return <>{children}</>;
}
export default function PermissionsGate({ children, scopes = [] }: any) {
  const permissionGranted = hasPermission(scopes);

  if (!permissionGranted) return <></>;

  return <>{children}</>;
}

import { useEffect, useState } from "react";
import { Button, Modal, Spin } from "antd";
import useUnratedTicket from "hooks/query/tickets/unratedTicket";
import { UserModel } from "models/users";
import { useAppSelector } from "store";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { Roles } from "utils/helpers/static_data";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { isClient } from "utils/helpers/check-roles";
import ModalOverlay from "components/Modals/ModalOverlay";
import i18next, { t } from "i18next";

let isInit = true;

const UnratedTicket = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [User, setUser] = useState<UserModel | null>(null);
  const { isLoggedIn } = useAppSelector((state) => state.auth);

  // Enable request just for client role
  const enabled = isClient(User?.role_id!);

  const { data, isLoading, isFetching } = useUnratedTicket(User?.id!, enabled);
  const location = useLocation();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setUser(UserData.Decrypt());
  }, [UserData.Decrypt]);

  // Unrated tickets counts.
  const unratedTicketsNum = data?.data.current_number;

  // Limit number that client can pass it on to continue with application.
  const limitNum = data?.data.limit_number!;

  // Check if unrated tickets overed the limit.
  const overedLimit = unratedTicketsNum! >= limitNum;

  // Number of tickets the clients must rate it at least.
  const ticketsMustRate = unratedTicketsNum! - limitNum + 1;

  // Array of done tickets.
  const doneTickets = data?.data.done_tickets;

  useEffect(() => {
    if (
      isLoggedIn &&
      User?.id &&
      User.role_id === Roles.client &&
      location.pathname === "/c-tickets" &&
      data?.data
    ) {
      if (unratedTicketsNum! > 0 && isInit) {
        setIsModalOpen(true);
        isInit = overedLimit;
      }
    }
  }, [isLoggedIn, User?.id, location.pathname, data?.data, unratedTicketsNum]);

  useEffect(() => {
    if (unratedTicketsNum === 0) {
      handleCancel();
    }
  }, [unratedTicketsNum]);

  function checkAndHumanize(num: number) {
    if (num === 1) {
      return i18next.language === "ar" ? "تذكرة" : "a ticket";
    } else if (num === 2) {
      return i18next.language === "ar" ? "تذكرتين" : "Two tickets";
    } else {
      return i18next.language === "ar" ? `${num} تذاكر` : `${num} tickets`;
    }
  }

  return data?.data ? (
    <>
      <Modal
        className="unrated-modal"
        title={
          <>
            <p
              style={{
                marginBottom: "4px",
              }}
            >
              {t("tickets_for_rating")}
            </p>
            {!overedLimit ? (
              <p className="label-modal">
                {t("you_have")} {checkAndHumanize(unratedTicketsNum!)}{" "}
                {t("unrated")}
              </p>
            ) : (
              <p className="label-modal">
                {t("you_have")} {checkAndHumanize(unratedTicketsNum!)}{" "}
                {t("unrated")}، {t("rate_plz")}{" "}
                {checkAndHumanize(ticketsMustRate)} {t("continue_possibility")}
              </p>
            )}
          </>
        }
        okText={t("rate_now")}
        footer={
          !overedLimit ? (
            <Button onClick={handleCancel} type="default">
              {t("igonre")}
            </Button>
          ) : null
        }
        open={isModalOpen}
        onCancel={!overedLimit ? handleCancel : () => null}
        closable={!overedLimit}
        bodyStyle={{
          maxHeight: "300px",
          overflowY: "scroll",
          padding: "0 1rem",
        }}
      >
        <div
          style={{
            marginTop: "15px",
          }}
        >
          <div>
            {doneTickets?.map((ticket: any) => {
              return (
                <div key={ticket.id} className="tickets-list">
                  <span>
                    {t("ticket_id")}: {ticket.public_id}
                  </span>
                  <Link
                    to={`/c-tickets/${ticket.ticket_id}`}
                    type="link"
                    onClick={handleCancel}
                  >
                    {t("rate_now")}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <ModalOverlay isLoading={isLoading} isFetching={isFetching} />
      </Modal>
    </>
  ) : null;
};

export default UnratedTicket;

import { Col, Row, Table } from "antd";
import { ColumnType } from "antd/es/table";
import EditLevelModal from "components/Modals/categoriesModals/editLevelModal";
import useLevels from "hooks/query/levels/useLevels";
import { LevelModel } from "models/levels";
import { useEffect, useState } from "react";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { Roles } from "utils/helpers/static_data";
import { UserModel } from "models/users";

import { useWidth } from "utils/helpers/use-is-mobile";
import Color from "utils/helpers/export-sass-colors";
import { hasPermission } from "components/permissionsGate";
import { t } from "i18next";

const CPLevels = () => {
  const [page, setPage] = useState({
    pageNumber: 0,
    perPage: 8,
  });

  const { isMobile } = useWidth();

  const [User, setUser] = useState<UserModel | null>(null);

  const {
    data: levelsData,
    isLoading,
    isFetching,
  } = useLevels(page.pageNumber, page.perPage);

  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  const columns: ColumnType<LevelModel>[] = [
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      width: "20%",
    },
    {
      title: t("price_in_hour"),
      dataIndex: "cost",
      align: "center",
      width: "20%",
    },
    {
      title: t("time_for_price_in_minutes"),
      dataIndex: "period",
      align: "center",
      width: "20%",
    },
    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 150,
      render(value, record, index) {
        if (hasPermission(["edit_categories"]))
          return <EditLevelModal item={record} />;
      },
    },
  ];

  const deviceColumns: ColumnType<LevelModel>[] = [
    {
      title: "",
      render: (obj: LevelModel) => {
        return (
          <div
            style={{
              padding: "0",
              borderRadius: "18px",
              overflow: "hidden",
              border: `2px solid ${Color.blue}`,
              width: "90%",
              margin: "auto",
            }}
          >
            <Row style={{ padding: "20px" }}>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span>{t("name")}: </span>
                  {obj.name_ar}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span>{t("price_in_hour")}: </span>
                  {obj.cost}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span>{t("time_for_price_in_minutes")}: </span>
                  {obj.period}
                </div>
              </Col>
              {hasPermission(["edit_categories"]) && (
                <Col md={12} span={24}>
                  <EditLevelModal item={obj} />
                </Col>
              )}
            </Row>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="categories">
        <Table
          bordered
          columns={isMobile ? deviceColumns : columns}
          dataSource={levelsData?.data}
          loading={isLoading || isFetching}
          pagination={false}
        />
      </div>
    </div>
  );
};
export default CPLevels;

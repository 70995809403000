import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import ActivePlaceIcon from "assets/images/office-building-active.png";
import PlaceIcon from "assets/images/office-building.png";

export interface MarkerProps {
  active?: boolean;
  card?: React.ReactElement;
  position: google.maps.LatLngLiteral;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  icon?: google.maps.Icon;
  type?:
    | "checkIn"
    | "checkOut"
    | "go-checkIn"
    | "go-checkOut"
    | "away-checkIn"
    | "away-checkOut";
  disabled?: boolean;
  activeIcon?: any;
  center?: any;
}

const Marker = (options: MarkerProps) => {
  const { active, card, onClick, type, disabled, activeIcon, center } = options;

  const [marker, setMarker] = useState<google.maps.Marker>();

  let title: any;
  let label: any;

  if (type === "checkIn") {
    title = "in";
    label = "in";
  } else if (type === "checkOut") {
    title = "out";
    label = "out";
  } else if (type === "go-checkIn") {
    title = "go-in";
    label = "go-in";
  } else if (type === "go-checkOut") {
    title = "go-out";
    label = "go-out";
  } else if (type === "away-checkIn") {
    title = "away-in";
    label = "away-in";
  } else if (type === "away-checkOut") {
    title = "away-out";
    label = "away-out";
  }

  useEffect(() => {
    setMarker(
      new google.maps.Marker({
        icon:
          active !== undefined
            ? {
                url: active ? ActivePlaceIcon : PlaceIcon,
                size: new google.maps.Size(37, 37),
                anchor: new google.maps.Point(20, 37),
                scaledSize: new google.maps.Size(37, 37),
              }
            : activeIcon
            ? {
                url: activeIcon,
                size: new google.maps.Size(37, 37),
                anchor: new google.maps.Point(20, 37),
                scaledSize: new google.maps.Size(37, 37),
              }
            : undefined,
      })
    );
  }, [center]);

  useEffect(() => {
    if (!marker) {
      console.log("marker changed");
      setMarker(
        new google.maps.Marker({
          icon:
            active !== undefined
              ? {
                  url: active ? ActivePlaceIcon : PlaceIcon,
                  size: new google.maps.Size(37, 37),
                  anchor: new google.maps.Point(20, 37),
                  scaledSize: new google.maps.Size(37, 37),
                }
              : activeIcon
              ? {
                  url: activeIcon,
                  size: new google.maps.Size(37, 37),
                  anchor: new google.maps.Point(20, 37),
                  scaledSize: new google.maps.Size(37, 37),
                }
              : undefined,
        })
      );
    }

    const infowindow = new google.maps.InfoWindow({
      content: card ? renderToString(card).toString() : undefined,
    });

    // infowindow.open({
    //   anchor: marker,
    //   shouldFocus: false,
    // });

    if (marker !== undefined) {
      if (card) {
        marker.addListener("click", () => {
          infowindow.open({
            anchor: marker,
            shouldFocus: false,
          });
        });
      }
      if (!disabled) {
        marker.addListener("click", (e: any) => {
          if (onClick) {
            onClick(e);
          }
        });
      }
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker, center]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default Marker;

import { AxiosRequestConfig } from "axios";
import { LevelModel } from "models/levels";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class LevelsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async FetchLevels(
    page: number,
    perPage: number
  ): Promise<ApiResponse<LevelModel>> {
    return this.get(`/api/levels?page=${page}&items_per_page=${perPage}`);
  }
  public async UpdateLevels(data: any, id: any): Promise<ApiResponse<any>> {
    return this.post(`/api/levels/${id}`, data);
  }
}

const levelsService = new LevelsService();

export const FetchLevels = async (page: number, perPage: number) => {
  const result: any = await levelsService.FetchLevels(page, perPage);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const UpdateLevelService = async ({ id, data }: any) => {
  const result: any = await levelsService.UpdateLevels(data, id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

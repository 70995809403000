import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { SendOtpAsync } from "services/users";
import { t } from "i18next";

export default function SendOtp() {
  return {
    ...useMutation(SendOtpAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message: t("a_verification_code_is_sent_to"),
          });
        }
      },
    }),
  };
}

import { Form, Select } from "antd";
import { Rule } from "antd/es/form";
import { useEffect, useState } from "react";
import { UserModel } from "models/users";
import useInfinityClients from "hooks/query/companies/useInfinityClients";
import { settingInterface } from "DTO/interfaces";
import { FormInstance } from "antd/lib/form";
import { SplitPhone } from "utils/helpers/split-phone";
import { loadingMoreSpinner } from "utils/ui/ScrollLoading";
import { t } from "i18next";

const { Option } = Select;

export const Client = ({
  name,
  required = false,
  disabled = false,
  rules,
  enabled = false,
  form,
  setUserContact,
  clientRequest,
  ticket,
}: {
  name: string;
  required?: boolean;
  disabled?: boolean;
  rules?: Rule[];
  enabled: boolean;
  form: FormInstance;
  setUserContact: any;
  clientRequest?: any;
  ticket?: any;
}) => {
  const [ClientsSetting, setClientsSetting] = useState<
    settingInterface<UserModel[]>
  >({
    page: 0,
    perPage: 12,
    loadingMore: false,
    data: [],
    search: "",
  });

  const [focused, setFocuesed] = useState(false);

  const { data, fetchNextPage, status, isLoading, isFetching } =
    useInfinityClients(
      ClientsSetting.page,
      ClientsSetting.perPage ?? 12,
      ClientsSetting.search ?? "",
      "name_search",
      enabled && focused
    );
  const changeClientSearch = (e: any) => {
    let newArr = ClientsSetting;
    newArr.search = e;
    newArr.loadingMore = false;
    newArr.page = 0;
    setClientsSetting({ ...newArr });
  };

  useEffect(() => {
    if (status === "success") {
      let allClients: any = [];

      data?.pages &&
        data?.pages[0] !== undefined &&
        data?.pages.forEach((page) => {
          if (page !== null) allClients = [...allClients, ...page.data];
        });
      let newArr = ClientsSetting;
      newArr.data = allClients;

      if (data?.pages[data?.pages.length - 1]?.data.length! > 0) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setClientsSetting({ ...newArr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages]);

  const handleClientScroll = (event: any) => {
    let delta: any;
    let newArr = ClientsSetting;
    let newMin = newArr.page;
    if (event.wheelDelta) {
      delta = event.wheelDelta;
    } else {
      delta = -1 * event.deltaY;
    }
    if (!newArr.loadingMore) {
      if (delta < 0) {
        newMin = newArr.page + 1;
        let length = newArr.data.length;
        if (length !== undefined) {
          if (newMin * 4 >= length) {
            newArr.loadingMore = true;
            newArr.page = newArr.page + 1;
            fetchNextPage();
          }
        }
      } else if (delta > 0 && newArr.page >= 1) {
        newMin = newArr.page - 1;
      }
    }
    newArr.page = newMin;
    setClientsSetting({ ...newArr });
  };

  useEffect(() => {
    if (
      clientRequest &&
      clientRequest.user &&
      clientRequest.user.phone_number
    ) {
      setUserContact({
        phone: SplitPhone(clientRequest.user.phone_number),
        title: clientRequest.user.full_name,
      });
    }
  }, [clientRequest?.user]);
  return (
    <Form.Item label={<>{t("contractor")}</>} name={name} rules={rules}>
      <Select
        showSearch
        disabled={disabled || clientRequest || ticket}
        optionFilterProp="children"
        filterOption={ClientsSetting.data as any}
        onSearch={(e: any) => changeClientSearch(e)}
        dropdownRender={(menu: any) => (
          <div onWheel={handleClientScroll}>{menu}</div>
        )}
        onSelect={(_, option) => {
          if (option["data-contact"])
            setUserContact({
              phone:
                option["data-contact"] && SplitPhone(option["data-contact"]),
              title: option["children"],
            });
        }}
        onFocus={() => setFocuesed(true)}
      >
        {ClientsSetting.data.map((item) => (
          <Option
            style={{ direction: "rtl", textAlign: "right" }}
            key={item.id.toString()}
            data-contact={
              item.phone_number ??
              item.contacts?.filter((value) => value.type === "phone")[0]
                ?.value ??
              undefined
            }
          >
            {item.full_name}
          </Option>
        ))}
        {(isLoading || isFetching) && loadingMoreSpinner}
      </Select>
    </Form.Item>
  );
};

import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import AddContact from "hooks/query/contacts/addContact";
import useAllContacts from "hooks/query/contacts/useAllContacts";
import Color from "utils/helpers/export-sass-colors";
import Check from "assets/images/check.png";
import i18next, { t } from "i18next";

/**
 * show contacts in Tickts
 */
const TicketContactsModal = (props: any) => {
  const [ContactForm] = Form.useForm();

  const { data, isLoading } = useAllContacts(props.placeId);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const addContact = AddContact();

  const [ContactsError, setContactsError] = useState<any>({});

  useEffect(() => {
    ContactForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.placeId]);

  const finishContact = (e: any) => {
    const formData = new FormData();
    if (e.contacts)
      e.contacts.forEach((contact: any) => {
        if (contact.value)
          formData.append("contacts[]", JSON.stringify(contact));
      });

    addContact
      .mutateAsync({ id: props.placeId, data: formData })
      .then((res) => {
        setContactsError({});
      })
      .catch((res: any) => {
        setContactsError(res.errors);
      });
  };

  return (
    <Modal
      key={"BranshModal" + props.placeId}
      width={700}
      onCancel={() => {
        props.close();
      }}
      open={props.modal}
      centered
      footer={[
        <Button
          type="primary"
          onClick={() => props.close()}
          className="closeBtn"
          key="back"
        >
          {t("close")}
        </Button>,
      ]}
      className="contactsModal"
    >
      <div>
        <div style={{ textAlign: "center", padding: "16px 24px" }}>
          {t("contacts")}
        </div>
        {props.placeId === "0" ? (
          <div style={{ textAlign: "center", padding: "16px 24px" }}>
            {t("please_select_company_first")}
          </div>
        ) : data?.data.length === 0 ? (
          <div style={{ textAlign: "center", padding: "16px 24px" }}>
            {i18next.language === "ar" ? "لا يوجد جهات اتصال!" : "No Contacts"}
          </div>
        ) : !isLoading && data?.data ? (
          <>
            <Form layout="vertical" form={ContactForm} onFinish={finishContact}>
              <Form.List
                name={"contacts"}
                initialValue={
                  data?.data.length !== 0 ? data?.data : [undefined]
                }
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(
                          new Error("you should have at least 1 Goods Type")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Row gutter={[10, 0]}>
                      {fields.map((field: any, index) => (
                        <Col key={index} md={12} span={24}>
                          <Form.Item
                            label={
                              data?.data[index]?.user !== null
                                ? `${
                                    data?.data[index]?.user?.full_name !== null
                                      ? data?.data[index]?.user?.full_name
                                      : " "
                                  }`
                                : " "
                            }
                            validateStatus={
                              "contacts." + index in ContactsError
                                ? "error"
                                : "success"
                            }
                            help={
                              "contacts." + index in ContactsError &&
                              i18next.language === "ar"
                                ? "يرجى ادخال جهة اتصال صحيحة"
                                : "Please input valid contact"
                            }
                            required={false}
                            key={field.key}
                          >
                            <Form.Item
                              fieldKey={field.fieldKey}
                              isListField={field.isListField}
                              key={field.key}
                              name={[field.name, "value"]}
                              validateTrigger={["onChange", "onBlur"]}
                              noStyle
                            >
                              <Input
                                style={{
                                  direction: "ltr",
                                  textAlign: "left",
                                  cursor: "default",
                                }}
                                readOnly
                              />
                            </Form.Item>
                            <Form.Item>
                              {props?.phones?.some(
                                (item: any) =>
                                  `+${item.phone.code}${item.phone.phone}` ===
                                  data?.data[index].value.replaceAll(" ", "")
                              ) ? (
                                <div
                                  className="selectContactBtn"
                                  style={{
                                    cursor: "default",
                                    backgroundColor: Color.darkGreen,
                                  }}
                                >
                                  <img src={Check} alt="" />
                                </div>
                              ) : (
                                <Button
                                  key="add"
                                  className="selectContactBtn"
                                  type="primary"
                                  onClick={() => {
                                    props.addContact(data?.data[index]);
                                  }}
                                >
                                  {t("add")}
                                </Button>
                              )}
                            </Form.Item>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>
                  </>
                )}
              </Form.List>
            </Form>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingBlock: "10vh",
            }}
            className="SpinCon"
          >
            <Spin indicator={antIcon} size="large" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TicketContactsModal;

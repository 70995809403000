import { useEffect, useState } from "react";

const useDebounce = (param: string) => {
  const [newParam, setNemParam] = useState(param);

  useEffect(() => {
    if (param !== newParam) {
      const timer = setTimeout(() => setNemParam(param), 700);
      return () => clearTimeout(timer);
    }
  }, [param]);

  return {
    newParam,
  };
};

export default useDebounce;

import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import Color from "utils/helpers/export-sass-colors";
const BackButton = ({
  title,
  onClick,
  dir = "right",
}: {
  title: string;
  onClick: () => void;
  dir?: "right" | "left";
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "0.2rem",
        border: "none",
        backgroundColor: "#fff",
        color: Color.blue_2,
      }}
      className="historyBtn"
      type="button"
    >
      {dir === "right" ? (
        <GoArrowRight fontSize="1.2rem" />
      ) : (
        <GoArrowLeft fontSize="1.2rem" />
      )}

      <span>{title}</span>
    </button>
  );
};

export default BackButton;

import { ApiError } from "utils/api/models/api-error";
import { useQuery } from "react-query";
import ApiResult from "utils/api/models/api-result";
import { WorkplaceModel } from "models/workplaces";
import { FetchWorkPlaces } from "services/workplaces";

export default function useWorkplaces() {
  const queryKey = ["workplaces"];
  return {
    ...useQuery<ApiResult<WorkplaceModel[]>, ApiError>(
      queryKey,
      () => FetchWorkPlaces(),
      {
        staleTime: Infinity,
      }
    ),
  };
}

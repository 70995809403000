import { Result } from "antd";
import { t } from "i18next";

interface Props {
  title?: string;
}

const Forbidden = ({ title }: Props) => {
  return <Result status="403" title={title || t("dont_have_pirmission")} />;
};

export default Forbidden;

import useDebounce from "hooks/others/useDebounce";
import { FinalReport } from "models/reports";
import { useQuery } from "react-query";
import { GetFinalReports } from "services/reports";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";

export default function useFinalReports(
  page: number,
  perPage: number,
  SearchBy: any,
  SearchText: any
) {
  const { newParam: searchParam } = useDebounce(SearchText);

  const queryKey = ["finalReports", page, perPage, SearchBy, searchParam];
  return {
    ...useQuery<ApiResult<FinalReport[]>, ApiError>(
      queryKey,
      () => GetFinalReports(page, perPage, SearchBy, SearchText),
      {
        staleTime: 30000,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
      }
    ),
  };
}

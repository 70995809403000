import { AxiosRequestConfig } from "axios";
import { hasPermission } from "components/permissionsGate";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";
import { CompanyModel } from "models/companies";

class CompaniesService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(
    page: any,
    perPage: any,
    searchBy: any,
    search: any,
    type: string,
    classification?: any
  ): Promise<ApiResponse<CompanyModel[]>> {
    return this.get(
      `/api/companies?page=${page}&items_per_page=${perPage}${
        search !== "" ? "&filter[" + searchBy + "]=" + search : ""
      }&index_type=${type}${
        classification && classification !== ""
          ? "&filter[classification_id]=" + classification
          : ""
      }`
    );
  }
  public async FetchSingle(id: any): Promise<ApiResponse<CompanyModel>> {
    return this.get(`/api/companies/${id}`);
  }
  public async FetchUsers(
    id: any,
    page: any,
    perPage: any
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/users?page=${page}&items_per_page=${perPage}&index_type=selector&${
        id !== "0" ? `filter[place_id]=${id}` : ``
      }`
    );
  }
  public async FetchUsersNone(
    compID: any,
    page: any,
    perPage: any,
    index_type: any,
    search: string
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/users?page=${page}&items_per_page=${perPage}&sort=company_id${
        index_type !== "" ? `&index_type=${index_type}` : ""
      }&${
        compID !== "0" ? `filter[company_user_with_no_company]=${compID}` : ``
      }${search !== "" ? "&filter[name_search]=" + search : ""}`
    );
  }
  public async FetchClients(
    page: number,
    perPage: number,
    search: string,
    searchBy: string
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/companies/0/users?page=${page}&items_per_page=${perPage}${
        search !== "" ? "&filter[" + searchBy + "]=" + search : ""
      }`
    );
  }
  public async FetchClientsByCompany(
    page: number,
    id: number
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/companies/${id}/users?page=${page}
      }`
    );
  }
  public async add(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/companies`, data);
  }
  public async edit(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/companies/${id}`, data);
  }
  public async delete(id: any): Promise<ApiResponse<any>> {
    return super.delete(`/api/companies/${id}`);
  }

  public async StoreImg(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/companies/logo`, data);
  }
  public async UploadImg(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/companies/${id}/logo`, data);
  }
  public async Merge(id: number, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/companies/${id}/merge`, data);
  }
}

const companiesService = new CompaniesService();

export const FetchCompanies = async (
  page: any,
  perPage: any,
  searchBy: any,
  search: any,
  type: string,
  classification?: any
) => {
  if (hasPermission(["browse_companies"])) {
    const result: any = await companiesService.Fetch(
      page,
      perPage,
      searchBy,
      search,
      type,
      classification
    );
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchCompany = async (id: any) => {
  if (hasPermission(["browse_companies"]) && id !== "0") {
    const result: any = await companiesService.FetchSingle(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchUsersCompany = async (id: any, page: any, perPage: any) => {
  if (hasPermission(["browse_users"]) && id !== "0") {
    const result: any = await companiesService.FetchUsers(id, page, perPage);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchUsersNoneCompany = async (
  compID: any,
  page: any,
  perPage: any,
  index_type: any,
  search: string
) => {
  if (hasPermission(["browse_users"])) {
    const result: any = await companiesService.FetchUsersNone(
      compID,
      page,
      perPage,
      index_type,
      search
    );
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchClients = async (
  page: number,
  perPage: number,
  search: string,
  searchBy: string
) => {
  if (hasPermission(["browse_users"])) {
    const result: any = await companiesService.FetchClients(
      page,
      perPage,
      search,
      searchBy
    );
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchClientsByCompany = async (page: number, id: number) => {
  if (hasPermission(["browse_users"])) {
    const result: any = await companiesService.FetchClientsByCompany(page, id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const AddCompanyAsync = async (data: any) => {
  const result: any = await companiesService.add(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const EditCompanyAsync = async ({ id, data }: any) => {
  const result: any = await companiesService.edit(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const DeleteCompanyAsync = async (id: any) => {
  const result: any = await companiesService.delete(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const StoreCompanyImgAsync = async (data: any) => {
  const result: any = await companiesService.StoreImg(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const UploadCompanyImgAsync = async ({ id, data }: any) => {
  const result: any = await companiesService.UploadImg(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const MergeCompany = async ({ id, data }: { id: number; data: any }) => {
  const result: any = await companiesService.Merge(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

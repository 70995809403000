import { hasPermission } from "components/permissionsGate";
import TicketInput from "../forms/inputs";
import { Ticket } from "utils/helpers/static_data";
import { FormInstance, UploadFile } from "antd";

import "./style.css";
import { t } from "i18next";

interface UploadInputProps {
  Status: any;
  FileList: UploadFile<any>[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  ImageList: UploadFile<any>[];
  setImageList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  form: FormInstance;
}

const UploadInput = ({
  Status,
  FileList,
  setFileList,
  ImageList,
  setImageList,
  form,
}: UploadInputProps) => {
  return (
    <>
      <TicketInput.Upload
        name={"images"}
        label={t("images")}
        accept=".png,.jpg,.jpeg"
        size={5}
        buttonLabel={t("image")}
        disabled={
          !hasPermission([
            Status === Ticket.create ? "add_tickets" : "edit_tickets",
          ])
        }
        FileList={ImageList}
        setFileList={setImageList}
        form={form}
      />
      <TicketInput.Upload
        name={"files"}
        label={t("files")}
        accept=".txt,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.dwg"
        size={25}
        buttonLabel={t("file")}
        disabled={
          !hasPermission([
            Status === Ticket.create ? "add_tickets" : "edit_tickets",
          ])
        }
        FileList={FileList}
        setFileList={setFileList}
        form={form}
      />
    </>
  );
};

export default UploadInput;

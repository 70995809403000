import { Typography } from "antd";
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Container from "../container";
import ForgetPhoneInput from "./phoneInput";
import OTPNewPassword from "./OTP_newPassword";
import { useAppSelector } from "store";
import { useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";
import { FaArrowRight } from "react-icons/fa";
import BackButton from "components/UI/BackButton";

const { Title } = Typography;
/**
 * forget password page
 *
 */
const Forget = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const [countDown, setCountDown] = useState("");
  const [Status, setStatus] = useState<"number" | "otp">("number");
  const [Number, setNumber] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      navigate({ pathname: "/tickets" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <>
      <Container>
        <Title
          style={{ textAlign: "center", marginBlock: "20px 30px" }}
          level={5}
        >
          {t("forgot_password")}
        </Title>
        <BackButton
          title={t("login")}
          onClick={() => navigate("/login")}
          dir={i18next.language === "ar" ? "right" : "left"}
        />
        {Status === "number" && (
          <ForgetPhoneInput
            setCountDown={setCountDown}
            setNumber={setNumber}
            setStatus={setStatus}
          />
        )}
        {Status === "otp" && (
          <OTPNewPassword
            setStatus={setStatus}
            setCountDown={setCountDown}
            countDown={countDown}
            Number={Number}
          />
        )}
      </Container>
    </>
  );
};

export default Forget;

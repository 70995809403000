import { hasPermission } from "components/permissionsGate";
import TicketInput from "../forms/inputs";
import { Ticket } from "utils/helpers/static_data";
import { useState } from "react";
import { WorkplaceModel } from "models/workplaces";
import { FormInstance } from "antd";
import { t } from "i18next";

interface WorkPlaceInputProps {
  Status: any;
  form: FormInstance<any>;
}

const WorkPlaceInput = ({ Status, form }: WorkPlaceInputProps) => {
  const [workplace, setWorkplace] = useState<WorkplaceModel[] | null>(null);
  return (
    <TicketInput.WorkPlace
      name="workplace"
      disabled={
        !hasPermission([
          Status === Ticket.create ? "add_tickets" : "edit_tickets",
        ])
      }
      rules={[
        {
          required: true,
          message: t("please_select_serving_place"),
        },
      ]}
      workPlace={workplace}
      form={form}
    />
  );
};

export default WorkPlaceInput;

import { hasPermission } from "components/permissionsGate";
import TicketInput from "../forms/inputs";
import { Ticket } from "utils/helpers/static_data";
import { FormInstance } from "antd";
import { t } from "i18next";

interface DescriptionInputProps {
  Status: any;
  form: FormInstance<any>;
}

const DescriptionInput = ({ form, Status }: DescriptionInputProps) => {
  return (
    <TicketInput.Description
      name="description"
      readOnly={
        !hasPermission([
          Status === Ticket.create ? "add_tickets" : "edit_tickets",
        ])
      }
      rules={[{ required: true, message: t("please_input_description") }]}
      form={form}
    />
  );
};

export default DescriptionInput;

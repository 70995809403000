import { CountryPhoneInputValue } from "antd-country-phone-input";
import { getCountryCode, getCounty } from "./countryCode";

export const SplitPhone = (phone_number: string) => {
  const country_phone: CountryPhoneInputValue = {
    code: getCountryCode(phone_number).countryCode.replace("+", ""),
    short: getCounty(phone_number),
    phone: getCountryCode(phone_number).withoutCountryCode,
  };
  return country_phone;
};

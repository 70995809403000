import { AsYouType } from "libphonenumber-js";

export function getCountryCode(input: any) {
  // Set default country code to US if no real country code is specified
  const defaultCountryCode: any = input.substr(0, 1) !== "+" ? "US" : null;
  let formatted = new AsYouType(defaultCountryCode).input(input);
  let countryCode: any = "";
  let withoutCountryCode = formatted;

  if (defaultCountryCode === "US") {
    countryCode = "+1";
    formatted = "+1 " + formatted;
  } else {
    const parts = formatted.split(" ");
    countryCode = parts.length > 1 ? parts.shift() : "";
    withoutCountryCode = parts.join("");
  }

  return {
    formatted,
    withoutCountryCode,
    countryCode,
  };
}

export const getCounty = (input: any) => {
  const asYouType = new AsYouType();
  asYouType.input(input);
  return asYouType.country;
};

import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { AddServiceAsync } from "services/Services";
import i18next from "i18next";

export default function AddService() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(AddServiceAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم إضافة الخدمة بنجاح"
                : "Service has been added successfully",
          });
          queryClient.invalidateQueries("services-by-category");
          queryClient.invalidateQueries("services-by-level");
        }
      },
    }),
  };
}

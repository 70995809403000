import { Rule } from "antd/lib/form";
import dayjs from "dayjs";
import { t } from "i18next";
interface inputRule {
  [key: string]: Rule[];
}

const InputRule: inputRule = {
  Password: [{ min: 7, message: t("invalid_password") }],
  PhoneNumber: [
    { max: 17, message: t("invalid_phone_number") },
    { min: 6, message: t("invalid_phone_number") },
  ],
};

export function dateValidator(_: any, value: any) {
  if (value) {
    const selectedDate = dayjs(value);
    const currentDate = dayjs();

    if (selectedDate.isBefore(currentDate, "day")) {
      return Promise.reject(t("invalid_date"));
    }
  }

  return Promise.resolve();
}

export default InputRule;

import { UserModel } from "models/users/index";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_NOT_VERIFY,
  LOGIN_DISABLED,
  LOGIN_FAIL,
  LOGOUT,
} from "actions/types";
interface initialState {
  isLoggedIn: boolean;
  // Auth: boolean;
  user: null | UserModel;
  err: null | string;
}

const INITIAL_STATE: initialState = {
  isLoggedIn: false,
  // Auth: true, //They planned to use it for someone is not authorized to use the app even if he got correct login creds
  user: null,
  err: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    checkAuth: (state, action: PayloadAction<any>) => {
      const { type, payload } = action.payload;
      switch (type) {
        case REGISTER_SUCCESS:
          return {
            ...state,
            isLoggedIn: false,
            user: null,
            // Auth: true,
            err: null,
          };
        case REGISTER_FAIL:
          return {
            ...state,
            isLoggedIn: false,
            user: null,
            // Auth: true,
            err: REGISTER_FAIL,
          };
        case LOGIN_SUCCESS:
          return {
            ...state,
            isLoggedIn: true,
            user: { ...payload.user },
            // Auth: true,
            err: null,
          };
        case LOGIN_NOT_VERIFY:
          return {
            ...state,
            isLoggedIn: false,
            user: null,
            // Auth: true,
            err: LOGIN_NOT_VERIFY,
          };
        case LOGIN_DISABLED:
          return {
            ...state,
            isLoggedIn: false,
            user: null,
            // Auth: true,
            err: LOGIN_DISABLED,
          };
        case LOGIN_FAIL:
          return {
            ...state,
            isLoggedIn: false,
            user: null,
            // Auth: true,
            err: LOGIN_FAIL,
          };
        case LOGOUT:
          return {
            ...INITIAL_STATE,
          };
        default:
          break;
      }
    },
    checkUser: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoggedIn: true,
        // Auth: true,
        user: action.payload,
        err: null,
      };
    },
  },
});

export default authSlice;

import { useMutation, useQueryClient } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { t } from "i18next";
import { SetTechnicianStandByStatus } from "services/technicians";

export default function useSetTechnicianStatus() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(SetTechnicianStandByStatus, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message: t("status_changed_successfully"),
          });
          queryClient.invalidateQueries(["technicianStatus"]);
        }
      },
    }),
  };
}

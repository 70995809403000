import dayjs from "dayjs";

const Footer = () => {
  return (
    <div className="footer">
      <div className="version">Version {process.env.REACT_APP_VERSION}</div>
      <div className="copyright">
        <a href={"https://izone-me.com/"} rel="noreferrer" target={"_blank"}>
          IZONE SOLUTIONS
        </a>{" "}
        {dayjs().format("YYYY")}©
      </div>
    </div>
  );
};

export default Footer;

import { useQuery } from "react-query";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";
import { TicketServiceModel } from "models/ticketServices";
import { FetchServicesByCategory } from "services/Services";

export default function useServicesByCategory(
  id: number,
  page: number,
  perPage: number,
  enabled: boolean
) {
  const queryKey = ["services-by-category", id, page, perPage];
  return {
    ...useQuery<ApiResult<TicketServiceModel[]>, ApiError>(
      queryKey,
      () => FetchServicesByCategory(id, page, perPage),
      {
        enabled: enabled,
        staleTime: 10000,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}

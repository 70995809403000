import { EyeFilled, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Pagination, Row, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import PermissionsGate from "components/permissionsGate";
import useClientRequest from "hooks/query/clientRequests/useClientRequest";
import { TicketModel } from "models/tickets";
import { UserModel } from "models/users";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { Roles, Ticket, TicketKeyName } from "utils/helpers/static_data";
import { useWidth } from "utils/helpers/use-is-mobile";

import "./table.scss";
import ExpandIcon from "./expandIcon";
import { StatusColor } from "utils/helpers/ticket_status";
import dayjs from "dayjs";
import format from "utils/helpers/date-format";

import AddTicketModal from "components/Modals/addTicketModal";
import { ClientRequestModel } from "models/clientRequests";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "components/SkeletonsLoading/SkeletonTable";
import { BiTransfer, BiUser } from "react-icons/bi";
import TicketTransferModal from "components/Modals/ticketTransferModal";
import Color from "utils/helpers/export-sass-colors";
import i18next, { t } from "i18next";

interface table {
  isLoading?: boolean;
  columns?: ColumnsType<any>;
  mobileColumns?: ColumnsType<any>;
  dataSource?: readonly any[];
  totalPage: number;
  page: (e: number) => void;
  current: number;
  isExpandable?: boolean;
  onExpand?: (expanded: boolean, record: any) => void;
  expandedRowKey?: any;
  ticketId?: string;
  ticketStatus?: any;
  enabled?: boolean;
  setAddTicketModalIsOpen?: any;
  linearLoading?: boolean;
}

const CustomTable = ({
  isLoading,
  columns,
  mobileColumns,
  dataSource,
  totalPage,
  page,
  current,
  isExpandable,
  onExpand,
  expandedRowKey,
  ticketId,
  ticketStatus,
  enabled,
  linearLoading,
}: table) => {
  const { isMobile } = useWidth();

  const [addTicketModalIsOpen, setAddTicketModalIsOpen] = useState(false);

  const [transferOpen, setTransferOpen] = useState(false);

  const [ticketsPage, setTicketsPage] = useState({
    page: 1,
    perPage: 8,
  });

  const [ticketTransInfo, setTicketTransInfo] = useState({
    ticketId: 0,
    userId: 0,
    crId: 0,
  });

  const {
    data: tickets,
    isLoading: ticketsIsLoading,
    isFetching,
  } = useClientRequest(
    +ticketId!,
    ticketsPage.page,
    "0",
    "-1",
    "",
    "",
    "",
    0,
    "",
    "0",
    0,
    enabled
  );

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  const ticketsColumns: ColumnsType<ClientRequestModel> = [
    {
      title: t("ticket_id"),
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (id, obj: ClientRequestModel) => (
        <div>
          <Tooltip
            title={
              obj.created_by.id == obj.user.id
                ? t("requested by client")
                : obj.description
            }
            color="white"
            placement={i18next.language === "ar" ? "left" : "right"}
          >
            <span
              style={{
                color: "white",
                backgroundColor:
                  obj.created_by.id == obj.user.id
                    ? Color.blue_4
                    : Color.blue_2,
                borderRadius: "8px",
                marginRight: "0.5rem",
                minWidth: "70px",
                display: "block",
              }}
              dir="ltr"
            >
              #{id}
            </span>
            <span
              dir="ltr"
              style={{
                display: "block",
                marginTop: "5px",
                backgroundColor:
                  obj.created_by.id == obj.user.id
                    ? Color.blue_4
                    : Color.blue_2,
                borderRadius: "8px",
                marginRight: "0.5rem",
                color: "white",
                minWidth: "70px",
              }}
            >
              <BiUser />
              {obj.public_id}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t("creator_by"),
      dataIndex: "created_by",
      key: "created_by",
      render: (created_by) => (
        <span style={{ fontWeight: "bold" }}>{created_by?.full_name}</span>
      ),
    },
    {
      title: t("created_at"),
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => (
        <span dir="ltr" style={{ fontWeight: "bold" }}>
          {dayjs(created_at).format(format.date_time)}
        </span>
      ),
    },
    {
      title: t("client_name"),
      dataIndex: "user",
      key: "user",

      render: (user, obj: ClientRequestModel) =>
        obj?.company_place?.company ? (
          <span style={{ fontWeight: "bold" }}>{user?.full_name}</span>
        ) : null,
    },

    {
      title: t("service"),
      dataIndex: "service",
      key: "service",
      render: (service) => (
        <span style={{ fontWeight: "bold" }}>
          {service?.name.length > 50
            ? service.name.slice(0, 50) + "..."
            : service.name}
        </span>
      ),
    },
    {
      title: t("priority"),
      dataIndex: "priority",
      key: "priority",
      render: (priority) => (
        <div style={{ textAlign: "center" }}>
          {priority === 0 && t("normal")}
          {priority === 1 && t("medium")}
          {priority === 2 && t("emergent")}
        </div>
      ),
    },
    {
      title: t("deadline"),
      dataIndex: "deadline",
      render: (deadline) => (
        <span style={{ fontWeight: "bold" }}>
          {dayjs(deadline).isValid()
            ? dayjs(deadline).format(format.date)
            : "-"}
        </span>
      ),
    },
    {
      title: t("status"),
      dataIndex: "ticket_status",
      key: "ticket_status",
      render: (status) => (
        <>
          <div
            className={`statusBtn status${status.id}`}
            style={{
              textTransform: "capitalize",
              fontSize: "12px",
              whiteSpace: "nowrap",
            }}
          >
            {Ticket.paid === status.id.toString() &&
            User?.role_id === Roles.client
              ? TicketKeyName.paidClient
              : status.status}
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: ["id", "ticket_status", "notifications_count"],
      // width: "6rem",
      render: (id: any, obj: ClientRequestModel) => (
        <div style={{ display: "flex", gap: "7px" }}>
          <PermissionsGate scopes={["change_ticket_request"]}>
            <Button
              style={{
                padding: "17px 11px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setTicketTransInfo({
                  ticketId: obj.id,
                  crId: obj.client_request_id,
                  userId: obj.user.id,
                });
                setTransferOpen(true);
              }}
              type="default"
            >
              <BiTransfer fontSize={"1.2rem"} />
            </Button>
          </PermissionsGate>
          <PermissionsGate scopes={["read_tickets"]}>
            <Link
              className="ticketLink"
              to={{
                pathname: `/tickets/${obj.id}`,
              }}
            >
              <span className="seeTicket">
                <EyeFilled />
                {obj.notifications_count !== null &&
                  obj?.notifications_count !== 0 && (
                    <span className="notificationNum">
                      {obj?.notifications_count}
                    </span>
                  )}
              </span>
            </Link>
          </PermissionsGate>
        </div>
      ),
    },
  ];

  const ticketsDeviceColumns: ColumnsType<ClientRequestModel> = [
    {
      title: "",
      render: (obj: ClientRequestModel) => {
        return (
          <div
            style={{
              border: `3px solid ${StatusColor(obj.ticket_status.id)}`,
              padding: "0",
            }}
            className="mobileCard"
          >
            <div className="label">
              <>
                <div
                  className={"statusBtn status" + obj.ticket_status.id}
                  style={{
                    borderRadius: "0 0 50% 50%",
                    textTransform: "uppercase",
                  }}
                >
                  {Ticket.paid === obj.ticket_status.id.toString() &&
                  User?.role_id === Roles.client
                    ? TicketKeyName.paidClient
                    : obj.ticket_status.status}
                </div>
              </>
            </div>
            <Row style={{ padding: "20px", paddingTop: "0" }}>
              <Col md={12} span={24}>
                <div className="label">
                  <span>{t("ticket_id")} :</span>
                  <span
                    style={{
                      backgroundColor:
                        obj.created_by?.company === null &&
                        !obj.created_by?.is_verified &&
                        User?.role_id !== Roles.client
                          ? `#FBC02D77`
                          : `${StatusColor(obj.ticket_status.id)}77`,
                      width: "fit-content",
                      padding: "7px",
                      borderRadius: "50%",
                    }}
                  >
                    {obj.id}
                  </span>
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span>{t("ticket_id_at_client")} :</span>
                  <span>{obj.public_id}</span>
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("creator_by")} :</span>
                  {obj.created_by?.full_name !== null &&
                    obj.created_by?.full_name !== undefined &&
                    obj.created_by?.full_name}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("created_at")} :</span>
                  <span dir="ltr">
                    {dayjs(obj?.created_at).format(format.date_time)}
                  </span>
                </div>
              </Col>
              {obj.company_place && (
                <Col md={12} span={24}>
                  <div className="label">
                    <span> {t("customer")} :</span>
                    {obj.company_place ? (
                      <span style={{ fontWeight: "bold" }}>
                        {obj.user.full_name}
                      </span>
                    ) : null}
                  </div>
                </Col>
              )}

              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("service")}: </span>
                  <span style={{ textAlign: "center" }}>
                    {obj.service.name}
                  </span>
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("priority")}: </span>
                  <span style={{ textAlign: "center" }}>
                    {obj.priority === 0 && "عادية"}
                    {obj.priority === 1 && "متوسطة"}
                    {obj.priority === 2 && "طارئة"}
                  </span>
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label">
                  <span> {t("deadline")}: </span>
                  <span style={{ textAlign: "center" }}>
                    {dayjs(obj.deadline).isValid()
                      ? dayjs(obj.deadline).format(format.date)
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col span={24}>
                <PermissionsGate scopes={["read_tickets"]}>
                  <Link
                    className="ticketLink"
                    to={{
                      pathname: `/tickets/${obj.id}`,
                    }}
                  >
                    <span style={{ marginTop: "0" }} className={"seeTicket"}>
                      <EyeFilled />
                      {t("view_ticket")}
                      {obj.notifications_count !== null &&
                        obj?.notifications_count !== 0 && (
                          <span className="notificationNum">
                            {obj?.notifications_count}
                          </span>
                        )}
                    </span>
                  </Link>
                </PermissionsGate>
                <PermissionsGate scopes={["change_ticket_request"]}>
                  <Button
                    onClick={() => {
                      setTicketTransInfo({
                        ticketId: obj.id,
                        crId: obj.client_request_id,
                        userId: obj.user.id,
                      });
                      setTransferOpen(true);
                    }}
                    style={{ marginRight: "12px" }}
                    icon={<BiTransfer />}
                  ></Button>
                </PermissionsGate>
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];

  const filteredColumns = ticketsColumns.filter((item) => item.key !== "user");

  const filteredTicketsData = tickets?.data.filter((item) =>
    ticketStatus != 0 ? item.ticket_status.id == ticketStatus : tickets?.data
  );

  return (
    <>
      <div className="ticketsTable">
        {linearLoading && <div className="loaderProgress" />}
        <SkeletonTable
          loading={isLoading}
          columns={columns as SkeletonTableColumnsType[]}
        >
          {isExpandable ? (
            <Table
              pagination={false}
              columns={isMobile ? mobileColumns : columns}
              dataSource={isLoading ? undefined : dataSource}
              rowClassName={(_, index) => {
                return index + 1 == expandedRowKey ? "expanded-row" : "";
              }}
              // scroll={{ x: !isMobile ? 1180 : undefined }}
              expandable={{
                expandedRowRender: (record: TicketModel) => {
                  if (isExpandable) {
                    return (
                      <>
                        <div
                          style={{
                            border: !isMobile ? "2px solid #bbb" : "",
                            padding: "1rem",
                            width: "100%",
                            margin: "auto",
                            borderRadius: "15px",
                          }}
                          className="active-cr"
                        >
                          {record && (
                            <PermissionsGate scopes={["add_tickets"]}>
                              <div
                                style={{
                                  marginBottom: "1rem",
                                  textAlign:
                                    i18next.language === "ar"
                                      ? "left"
                                      : "right",
                                }}
                              >
                                {!ticketsIsLoading && !isFetching && (
                                  <Button
                                    onClick={() =>
                                      setAddTicketModalIsOpen(true)
                                    }
                                    type="primary"
                                  >
                                    {t("add_ticket")}
                                  </Button>
                                )}
                              </div>
                            </PermissionsGate>
                          )}
                          <SkeletonTable
                            loading={isLoading}
                            columns={columns as SkeletonTableColumnsType[]}
                            rowCount={3}
                          >
                            <Table
                              loading={ticketsIsLoading || isFetching}
                              dataSource={
                                filteredTicketsData && ticketId !== ""
                                  ? filteredTicketsData
                                  : undefined
                              }
                              columns={
                                isMobile
                                  ? ticketsDeviceColumns
                                  : record.company_place
                                  ? ticketsColumns
                                  : filteredColumns
                              }
                              pagination={false}
                              style={{
                                width: "100%",
                                minHeight: "auto",
                              }}
                              // scroll={{ x: !isMobile ? 1100 : undefined }}
                            />
                          </SkeletonTable>
                          <Pagination
                            showSizeChanger={false}
                            pageSize={8}
                            onChange={(e) =>
                              setTicketsPage({
                                page: e,
                                perPage: 8,
                              })
                            }
                            current={tickets?.meta.currentPage}
                            total={tickets?.meta.total}
                          />
                        </div>
                      </>
                    );
                  }
                },
                expandIcon: ({ onExpand, record, expanded }: any) =>
                  isExpandable ? (
                    <ExpandIcon
                      isExpanded={expanded}
                      onClick={(e: any) => onExpand(record, e)}
                    />
                  ) : null,
                onExpand,
                expandRowByClick: isExpandable ? true : false,
                expandedRowKeys: [expandedRowKey!],
              }}
            />
          ) : (
            <Table
              pagination={false}
              columns={isMobile ? mobileColumns : columns}
              dataSource={isLoading ? undefined : dataSource}
              rowClassName={(_, index) => {
                return index + 1 == expandedRowKey ? "expanded-row" : "";
              }}
              // scroll={{ x: !isMobile ? 1180 : undefined }}
            />
          )}
        </SkeletonTable>
      </div>
      <Pagination
        showSizeChanger={false}
        pageSize={8}
        onChange={(e) => {
          page(e);
        }}
        current={current}
        total={totalPage}
      />
      {tickets?.data[0] && !ticketsIsLoading && !isFetching && (
        <AddTicketModal
          isOpen={addTicketModalIsOpen}
          setIsOpen={setAddTicketModalIsOpen}
          clientRequest={tickets?.data[0]}
        />
      )}
      <PermissionsGate scopes={["change_ticket_request"]}>
        <TicketTransferModal
          open={transferOpen}
          setOpen={setTransferOpen}
          userId={ticketTransInfo.userId}
          crId={ticketTransInfo.crId}
          ticketId={ticketTransInfo.ticketId}
        />
      </PermissionsGate>
    </>
  );
};

export default CustomTable;

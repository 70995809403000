import { UserModel } from "models/users/index";
import { useQuery } from "react-query";
import { FetchSalesMen } from "services/users";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { hasPermission } from "components/permissionsGate";

export default function useSalesMen() {
  const queryKey = ["salesMen"];
  return {
    ...useQuery<ApiResult<UserModel[]>, ApiError>(
      queryKey,
      () => FetchSalesMen(),
      { refetchOnWindowFocus: false, enabled: hasPermission(["browse_users"]) }
    ),
  };
}

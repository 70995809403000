import { Roles } from "./static_data";

export function isClient(roleId: number) {
  if (roleId === Roles.client) {
    return true;
  } else {
    return false;
  }
}

export function isAccountant(roleId: number) {
  if (roleId === Roles.accountant) {
    return true;
  } else {
    return false;
  }
}
export function isAdmin(roleId: number) {
  if (roleId === Roles.admin) return true;
  else return false;
}
export function isDispatcher(roleId: number) {
  if (roleId === Roles.dispatcher) return true;
  else return false;
}
export function isSales(roleId: number) {
  if (roleId === Roles.sales) return true;
  else return false;
}
export function isTechnical(roleId: number) {
  if (roleId === Roles.technical) return true;
  else return false;
}

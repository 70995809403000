/* eslint-disable no-useless-escape */
export const RegExp = {
  englishLetter: /^[A-Za-z ]+$/,
  arabicLetter: /^[\u0621-\u064A ]+$/,
  isValidEmail: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
  isValidPhone: /^(\+[0-9]{9,12})$/g,
  isValidWebsite:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  noTwoSpacesInRow: /^(?!.*\s{2,})(?!^ ).{0,}$/,
};

export const englishLetter = (value: string) => {
  const match = value.match(RegExp.englishLetter);
  const result = match ? true : false;
  return result;
};

export const arabicLetter = (value: string) => {
  const match = value.match(RegExp.arabicLetter);
  const result = match ? true : false;
  return result;
};

export const isValidEmail = (value: string) => {
  const match = value.match(RegExp.isValidEmail);
  const result = match ? true : false;
  return result;
};

export const isValidPhone = (value: string) => {
  const match = value.match(RegExp.isValidPhone);
  const result = match ? true : false;
  return result;
};

export const isValidWebsite = (value: string) => {
  const match = value.match(RegExp.isValidWebsite);
  const result = match ? true : false;
  return result;
};

export const noTwoSpacesInRow = (value: string) => {
  const match = value.match(RegExp.noTwoSpacesInRow);
  const result = match ? true : false;
  return result;
};

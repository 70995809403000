import { useQuery } from "react-query";
import {
  FetchCategoriesList,
  FetchCategoriesSelector,
} from "services/categories";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { CategoryModel } from "models/categories";

export default function useCategoriesSelector() {
  const queryKey = "categories-selector";
  return {
    ...useQuery<ApiResult<CategoryModel[]>, ApiError>(
      queryKey,
      () => FetchCategoriesSelector(),
      {
        staleTime: 10000,
        refetchOnWindowFocus: false,
      }
    ),
  };
}

export function useCategoriesList(enabled?: boolean) {
  const queryKey = ["categories-list", enabled];
  return {
    ...useQuery<ApiResult<CategoryModel[]>, ApiError>(
      queryKey,
      () => FetchCategoriesList(),
      {
        staleTime: 10000,
        enabled: enabled,
      }
    ),
  };
}

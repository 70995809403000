import { UserModel } from "./../../models/users/index";
import CryptoJS from "crypto-js";

const Encrypt = (user: UserModel): string => {
  const encryptedUser: string = CryptoJS.AES.encrypt(
    JSON.stringify(user),
    process.env.REACT_APP_SECRET_KEY as string
  ).toString();
  window.localStorage.setItem("user", encryptedUser);
  return encryptedUser;
};

const Decrypt = (): UserModel | null => {
  const localUser: string | null = window.localStorage.getItem("user") ?? null;
  const decryptedUser: UserModel | null = localUser
    ? JSON.parse(
        CryptoJS.AES.decrypt(
          localUser,
          process.env.REACT_APP_SECRET_KEY as string
        ).toString(CryptoJS.enc.Utf8) as string
      )
    : null;
  return decryptedUser;
};

const RemoveUser = (): void => {
  window.localStorage.removeItem("user");
};

const Set = (token: string): string => {
  window.localStorage.setItem("ADMIN_TOKEN", token);
  return token;
};

const Get = (): string | null => {
  const localToken: string | null =
    window.localStorage.getItem("ADMIN_TOKEN") ?? null;
  return localToken;
};

const RemoveToken = (): void => {
  window.localStorage.removeItem("ADMIN_TOKEN");
};

export const UserData = {
  Encrypt,
  Decrypt,
  Remove: RemoveUser,
};

export const Token = {
  Set,
  Get,
  Remove: RemoveToken,
};

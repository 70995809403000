import { hasPermission } from "components/permissionsGate";
import TicketInput from "../forms/inputs";
import { Ticket } from "utils/helpers/static_data";
import { FormInstance } from "antd";
import { useState } from "react";

interface CompanyPlaceUserInputProps {
  Status: any;
  form: FormInstance<any>;
  setCompanyPlace: any;
}

const CompanyPlaceUserInput = ({
  form,
  Status,
  setCompanyPlace,
}: CompanyPlaceUserInputProps) => {
  return (
    <TicketInput.CompanyPlaceUser
      name="company_place_user"
      setCompanyPlace={setCompanyPlace}
      disabled={
        !hasPermission([
          Status === Ticket.create ? "add_tickets" : "edit_tickets",
        ])
      }
      form={form}
    />
  );
};

export default CompanyPlaceUserInput;

import React, { useState } from "react";

const usePagination = (initialPerPage: number) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(initialPerPage);

  return {
    page,
    setPage,
    perPage,
    setPerPage,
  };
};

export default usePagination;

import { FetchClients } from "services/companies/index";
import { useInfiniteQuery } from "react-query";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { UserModel } from "models/users";
import useDebounce from "hooks/others/useDebounce";

export default function useInfinityClients(
  page: number,
  perPage: number,
  search: string,
  searchBy: string,
  enabled: boolean
) {
  const { newParam: searchParam } = useDebounce(search);

  const queryKey = ["clients", perPage, searchParam, searchBy];
  return {
    ...useInfiniteQuery<ApiResult<UserModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) =>
        await FetchClients(pageParam, perPage, search, searchBy),
      {
        enabled: enabled,
        staleTime: 10000,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}

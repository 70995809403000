import {
  Form,
  Upload as AntdUpload,
  Modal,
  notification,
  UploadFile,
} from "antd";
import ToolTip from "components/tooltip";
import { DeleteTwoTone, EyeTwoTone, PlusOutlined } from "@ant-design/icons";
import { AcceptFileType } from "utils/helpers/accept-file-type";
import { MB2Bytes } from "utils/helpers/mb-to-byte";
import { UploadChangeParam } from "antd/es/upload";
import { FormInstance } from "antd/lib/form";
import i18next, { t } from "i18next";

const { confirm } = Modal;

export const Upload = ({
  FileList,
  setFileList,
  disabled = false,
  name,
  label,
  accept,
  size,
  buttonLabel,
  form,
}: {
  FileList: UploadFile<any>[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  disabled: boolean;
  name: string;
  label: string;
  accept: string;
  size: number;
  buttonLabel: string;
  form: FormInstance;
}) => {
  const addFile = ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
    setFileList(fileList);
  };
  const uploadFileButton = (
    <div className="uploadImg">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{buttonLabel}</div>
    </div>
  );
  return (
    <Form.Item
      label={
        label ? (
          <ToolTip
            content={
              <>
                {t("accepted_types")} {accept.replaceAll(".", " ")}.
                <br />
                {t("max_accepted_size")}: {size}MB.
              </>
            }
          >
            {label}
          </ToolTip>
        ) : null
      }
      name={name}
    >
      <AntdUpload
        action=""
        listType="picture-card"
        fileList={FileList}
        accept={accept}
        onChange={addFile}
        showUploadList={{
          removeIcon: <DeleteTwoTone />,
          previewIcon: <EyeTwoTone />,
        }}
        onRemove={async (file) => {
          await new Promise((resolve, reject) => {
            confirm({
              title: (
                <div>
                  {t("sure_action")} {t("delete_file")}
                </div>
              ),
              content: (
                <>
                  <div>
                    <span dir="ltr">{file.name}</span>
                  </div>
                  <div>
                    <div>{t("note")}:</div>
                    {i18next.language === "ar"
                      ? "لن يتم الحذف الا عند تحديث معلومات التذكرة."
                      : "Delete doesn't be done before update ticket"}
                  </div>
                </>
              ),
              okText: t("accept"),
              cancelText: t("cancel"),
              onOk() {
                return resolve(null);
              },
              onCancel() {
                return reject();
              },
            });
          });
        }}
        beforeUpload={(file) => {
          const isAccept = AcceptFileType(
            file,
            accept.replaceAll(".", "").split(",")
          );
          const isSize = file.size < MB2Bytes(size);
          if (!isAccept) {
            notification.open({
              type: "error",
              message: "Upload Error",
              description: `file type not accepted`,
            });
          } else if (!isSize) {
            notification.open({
              type: "error",
              message: "Upload Error",
              description: `file larger than 25MB`,
            });
          }
          if (isAccept && isSize) {
            return false;
          } else {
            return AntdUpload.LIST_IGNORE;
          }
        }}
        disabled={disabled}
      >
        {!disabled ? uploadFileButton : null}
      </AntdUpload>
      {FileList.length === 0 && disabled ? t("no_data") : ""}
    </Form.Item>
  );
};

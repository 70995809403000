import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class ClientsClassifications extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }
  public async Fetch(): Promise<ApiResponse<any>> {
    return this.get("/api/classifications");
  }

  public async Post(data: any): Promise<ApiResponse<any>> {
    return this.post("/api/classifications", data);
  }
  public async Delete(id: any): Promise<ApiResponse<any>> {
    return this.delete(`/api/classifications/${id}`);
  }
  public async Update(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/classifications/${id}`, data);
  }
}

const clientsClassificationsService = new ClientsClassifications();

export const FetchClassifications = async () => {
  const result: any = await clientsClassificationsService.Fetch();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const PostClassification = async ({ data }: { data: any }) => {
  const result: any = await clientsClassificationsService.Post(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const DeleteClass = async (id: any) => {
  const result: any = await clientsClassificationsService.Delete(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const EditClassification = async ({
  id,
  data,
}: {
  id: number;
  data: any;
}) => {
  const result: any = await clientsClassificationsService.Update(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

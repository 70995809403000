import { useQuery } from "react-query";
import { CheckAccountAsync } from "services/users";
import { useAppSelector } from "store";

export default function CheckUser(enabled: any) {
  const queryKey = ["CheckUser"];

  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  return {
    ...useQuery(
      queryKey,
      () =>
        CheckAccountAsync()
          .then((res) => res)
          .catch((err) => err),
      {
        cacheTime: 0,
        refetchInterval: false,
        enabled: isLoggedIn && enabled && !true,
      }
    ),
  };
}

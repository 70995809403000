import { Button, Form, Input, Modal, Radio } from "antd";
import EditCategory from "hooks/query/categories/editCategory";
import UpdateLevel from "hooks/query/levels/updateLevel";
import { t } from "i18next";
import { LevelModel } from "models/levels";
import { useEffect, useState } from "react";
interface EditLevelModalProps {
  item: LevelModel;
}
const EditLevelModal = ({ item }: EditLevelModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const editLevel = UpdateLevel();
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, []);
  const handleClose = () => {
    form.setFieldsValue("");
    setIsOpen(false);
  };
  const onFinish = (e: any) => {
    const formData = new FormData();
    formData.append("cost", e.cost);
    formData.append("period", e.period);
    formData.append("_method", "PUT");
    editLevel.mutateAsync({ id: item?.id!, data: formData }).then(() => {
      handleClose();
    });
  };
  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {t("update")}
      </Button>
      <Modal
        key={"editCategory"}
        title={item?.name}
        open={isOpen}
        width={400}
        bodyStyle={{ padding: 20 }}
        destroyOnClose
        onCancel={() => handleClose()}
        confirmLoading={editLevel.isLoading}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            cost: item.cost,
            period: item.period,
          }}
          labelCol={{ span: 24 }}
          validateMessages={{
            required: t("please_input") + " ${label}!",
          }}
        >
          <Form.Item
            name={"cost"}
            label={t("price_in_hour")}
            rules={[{ required: true }]}
            required
          >
            <Input style={{ direction: "ltr" }} />
          </Form.Item>
          <Form.Item
            name={"period"}
            label={t("time_in_minutes")}
            rules={[{ required: true }]}
            required
          >
            <Input style={{ direction: "ltr" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditLevelModal;

import React from "react";
import { Button, Tooltip, notification } from "antd";
import useCurrentLocation from "hooks/others/useCurrentLocation";
import useSetTechnicianStatus from "hooks/query/users/useSetTechnicianStatus";
import useTechnicianStatus from "hooks/query/users/useTechnicianStatus";
import { useTranslation } from "react-i18next";
import { FaPowerOff } from "react-icons/fa6";

type Props = { isMobile: boolean };

function TechnicianStatusButton({ isMobile }: Props) {
  const [location, getLocationOnClick] = useCurrentLocation();
  const {
    isLoading: isLoadingGetStatus,
    isFetching,
    data: technicianStatus,
  } = useTechnicianStatus();
  const { isLoading: isLoadingPostStatus, mutateAsync } =
    useSetTechnicianStatus();

  const { t } = useTranslation();

  const handleSuccess = (location: any) => {
    const { latitude, longitude } = location;

    if (latitude !== null && longitude !== null) {
      mutateAsync({
        lat: latitude,
        lon: longitude,
      });
    } else {
      // Handle case where location is not available
      console.error("Location is not available.");
      notification.open({
        type: "warning",
        message: t("please_install_app_to_get_location"),
        duration: 7,
      });
    }
  };

  const handleClick = async () => {
    await getLocationOnClick(handleSuccess);
  };

  return isMobile ? (
    <Button
      type="primary"
      icon={<FaPowerOff />}
      loading={isLoadingPostStatus || isLoadingGetStatus || isFetching}
      onClick={handleClick}
      style={{
        width: "98%",
        margin: "30px 0",
        background:
          technicianStatus?.data.next_status == null
            ? technicianStatus?.data.current_status == "stand_by_on"
              ? "green"
              : "red"
            : "",
      }}
      disabled={technicianStatus?.data.next_status != null}
      color={
        technicianStatus?.data.next_status == null
          ? technicianStatus?.data.current_status == "stand_by_on"
            ? "green"
            : "red"
          : ""
      }
    />
  ) : (
    <Tooltip
      title={
        technicianStatus?.data.next_status != null
          ? t("you_have_an_active_report")
          : ""
      }
      color="white"
    >
      <Button
        type="primary"
        icon={<FaPowerOff />}
        loading={isLoadingPostStatus || isLoadingGetStatus || isFetching}
        onClick={handleClick}
        style={{
          width: "50px",
          background:
            technicianStatus?.data.next_status == null
              ? technicianStatus?.data.current_status == "stand_by_on"
                ? "green"
                : "red"
              : "",
        }}
        disabled={technicianStatus?.data.next_status != null}
        shape="round"
      />
    </Tooltip>
  );
}

export default TechnicianStatusButton;

import { Input } from "antd";

export const NumericInput = ({ value = "", onChange, ...rest }: any) => {
  const handleChange = (e: any) => {
    const newValue = e.target.value;

    // Allow only numbers
    if (/^\d*$/.test(newValue)) {
      onChange?.(newValue);
    }
  };

  return (
    <Input {...rest} value={value} onChange={handleChange} autoComplete="off" />
  );
};

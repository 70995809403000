import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class PlacesService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(
    id: any,
    page: any,
    perPage: any,
    search: any
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/companies/${id}/company-places?page=${page}&items_per_page=${perPage}${
        search !== "" ? "&filter[name]=" + search : ""
      }`
    );
  }
  public async FetchAll(
    page: any,
    perPage: any,
    search: any,
    companyId?: any
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/company-places?page=${page}&items_per_page=${perPage}${
        search !== "" ? "&filter[search]=" + search : ""
      }${companyId !== "" ? "&filter[company_id]=" + companyId : ""}`
    );
  }
  public async FetchSibling(id: any): Promise<ApiResponse<any>> {
    return this.get(`/api/company-places/${id}/current-company`);
  }
  public async FetchSingle(id: any, placeId: any): Promise<ApiResponse<any>> {
    return this.get(`/api/companies/${id}/company-places/${placeId}`);
  }

  public async FetchUserPlaces() {
    return this.get(`/api/company-places/user`);
  }

  public async add(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/companies/${id}/company-places`, data);
  }
  public async edit(
    id: any,
    placeId: any,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.put(`/api/companies/${id}/company-places/${placeId}`, data);
  }
  public async delete(id: any, placeId: any): Promise<ApiResponse<any>> {
    return super.delete(`/api/companies/${id}/company-places/${placeId}`);
  }
}

const placesService = new PlacesService();

export const FetchCompanyPlaces = async (
  id: any,
  page: any,
  perPage: any,
  search: any
) => {
  if (id.toString() !== "0") {
    const result: any = await placesService.Fetch(id, page, perPage, search);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchAllCompanyPlaces = async (
  page: any,
  perPage: any,
  search: any,
  companyId?: any
) => {
  const result: any = await placesService.FetchAll(
    page,
    perPage,
    search,
    companyId
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchCompanyPlace = async (id: any, placeId: any) => {
  if (placeId !== 0) {
    const result: any = await placesService.FetchSingle(id, placeId);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

export const FetchUserPlaces = async () => {
  const result: any = await placesService.FetchUserPlaces();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const AddCompanyPlaceAsync = async ({ id, data }: any) => {
  const result: any = await placesService.add(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const EditCompanyPlaceAsync = async ({ id, placeId, data }: any) => {
  const result: any = await placesService.edit(id, placeId, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const DeleteCompanyPlaceAsync = async ({ id, placeId }: any) => {
  const result: any = await placesService.delete(id, placeId);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchSiblingPlacesAsync = async (id: any) => {
  if (id && id !== "0") {
    const result: any = await placesService.FetchSibling(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

import { RcFile } from "antd/es/upload/interface";

const FileType: any = {
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  txt: "text/plain",
  pdf: "application/pdf",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  dwg: undefined, //AutoCAD Drawing Format
};

export const AcceptFileType = (file: RcFile, acceptType: string[]) => {
  let isAccept: boolean = false;
  const fileExtension: string =
    file.name.split(".")[file.name.split(".").length - 1];
  for (let type of acceptType) {
    if (FileType[type] === undefined) {
      if (fileExtension === type) {
        isAccept = true;
        break;
      }
    } else if (file.type === FileType[type]) {
      isAccept = true;
      break;
    }
    isAccept = false;
  }
  return isAccept;
};

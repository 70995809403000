import { Button, Collapse, Result } from "antd";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { t } from "i18next";

const InternalError = ({ error }: any) => {
  return (
    <Result
      status="500"
      title={t("unexpected_error_2")}
      subTitle={<div>{t("contact_tech_team")}</div>}
      extra={[
        <Button type="primary" onClick={() => window.location.replace("/")}>
          {t("return_home_page")}
        </Button>,
        <Button type="default" onClick={() => window.location.reload()}>
          {t("reload_page")}
        </Button>,
        <>
          {window.location.hostname === "localhost" && (
            <Collapse
              style={{ width: "50%", margin: "auto", marginTop: "10px" }}
              bordered={false}
            >
              <CollapsePanel key={"error"} header={t("technical_details")}>
                <div style={{ direction: "ltr", textAlign: "left" }}>
                  {error?.error?.message}
                </div>
              </CollapsePanel>
            </Collapse>
          )}
        </>,
      ]}
    />
  );
};

export default InternalError;

import { Form, Input } from "antd";
import { Rule } from "antd/es/form";
import ToolTip from "components/tooltip";
import { FormInstance } from "antd/lib/form";
import i18next, { t } from "i18next";

export const BillsInfo = ({
  name,
  required = false,
  disabled = false,
  rules,
  readOnly = false,
  form,
}: {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  rules: Rule[];
  readOnly?: boolean;
  form: FormInstance;
}) => {
  return (
    <Form.Item
      label={
        <ToolTip
          content={
            i18next.language === "ar"
              ? "عدد الفنيين المتوقع والساعات المتوقعة للتذكرة"
              : "Number of expected technicians and working hours"
          }
        >
          {t("pricing_info")}
        </ToolTip>
      }
      name={name}
      rules={rules}
      required={required}
    >
      <Input.TextArea
        readOnly={readOnly}
        disabled={disabled}
        dir="auto"
        autoComplete="off"
      />
    </Form.Item>
  );
};

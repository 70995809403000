import { UserModel } from "models/users/index";
import { useQuery } from "react-query";
import { FetchTechnicalUser } from "services/users";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { TichnicianStatusModel } from "models/technicians";
import { GetTechnicianCurrentStatus } from "services/technicians";

function getLastWord(str: string) {
  // Split the string by "\" and get the last element
  const words = str.split("\\");
  return words[words.length - 1];
}

export default function useTechnicianStatus() {
  const queryKey = ["technicianStatus"];
  return {
    ...useQuery<ApiResult<TichnicianStatusModel>, ApiError>(
      queryKey,
      () => GetTechnicianCurrentStatus(),
      {
        refetchOnWindowFocus: true,
        onSuccess: (data) => {
          let status = "";
          if (data.data.next_status != null) {
            //TransportationReport
            //TicketReport
            status = getLastWord(data.data.next_status);
          } else {
            //stand_by_on
            //stand_by_off
            status = data.data.current_status;
          }
          localStorage.setItem("technicianStatus", JSON.stringify(status));
        },
      }
    ),
  };
}

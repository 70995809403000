import { Form, Select, Spin } from "antd";
import { FormInstance, Rule } from "antd/es/form";
import { useEffect, useRef, useState } from "react";
import useAllPlaces from "hooks/query/places/useAllPlaces";
import Color from "utils/helpers/export-sass-colors";
import useUsersCompany from "hooks/query/companies/useUsersCompany";
import { PlaceModel } from "models/companies";
import { UserModel } from "models/users";
import { settingInterface } from "DTO/interfaces";
import { SplitPhone } from "utils/helpers/split-phone";
import { TicketModel } from "models/tickets";
import { loadingMoreSpinner } from "utils/ui/ScrollLoading";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import i18next, { t } from "i18next";

const { Option } = Select;

export const Company = ({
  name,
  required = false,
  disabled = false,
  rules,
  form,
  enabled = false,
  setCompanyPlace,
  setUserContact,
  ticket,
  clientRequest,
  company,
  setCompany,
  withFilter = false,
}: {
  name: string[];
  required?: boolean;
  disabled?: boolean;
  rules?: Rule[];
  form: FormInstance;
  enabled: boolean;
  setCompanyPlace: React.Dispatch<React.SetStateAction<any>>;
  setUserContact: React.Dispatch<React.SetStateAction<any>>;
  ticket?: TicketModel;
  clientRequest?: any;
  company: any;
  setCompany: any;
  withFilter?: any;
}) => {
  const [BranchesSetting, setBranchesSetting] = useState<
    settingInterface<PlaceModel[]>
  >({
    page: 0,
    perPage: 10,
    loadingMore: false,
    data: [],
    search: "",
  });

  const [UsersSetting, setUsersSetting] = useState<
    settingInterface<UserModel[]>
  >({
    page: 0,
    perPage: 12,
    loadingMore: false,
    data: [],
    search: "",
  });
  const [placesFocuesed, setPlacesFocuesed] = useState(false);
  const [usersFocuesed, setUsersFoucesed] = useState(false);

  useEffect(() => {
    if (ticket && ticket.company_place) {
      setCompany(ticket.company_place.id);
    } else if (clientRequest && clientRequest.company_place) {
      setCompany(clientRequest.company_place.id);
    }
  }, [ticket, clientRequest]);

  const {
    data,
    status,
    fetchNextPage,
    isLoading: placesLoading,
    isFetching: placesFetching,
  } = useAllPlaces({
    page: BranchesSetting.page,
    perPage: BranchesSetting.perPage ?? 10,
    search: BranchesSetting.search,
    enabled: enabled && placesFocuesed,
    companyId: withFilter ? ticket?.user?.company_id : "",
  });
  const UsersCompany = useUsersCompany(
    company,
    UsersSetting.page,
    UsersSetting.perPage ?? 12,
    enabled && usersFocuesed
  );

  useEffect(() => {
    if (status === "success") {
      let allUsersCompany: any = [];

      data?.pages &&
        data?.pages[0] !== undefined &&
        data?.pages.forEach((page: any) => {
          if (page !== null)
            allUsersCompany = [...allUsersCompany, ...page.data];
        });
      let newArr = BranchesSetting;
      newArr.data = allUsersCompany;

      if (data?.pages[data?.pages.length - 1]?.data.length > 0) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setBranchesSetting({ ...newArr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages]);

  useEffect(() => {
    if (UsersCompany.status === "success") {
      let allUsersCompany: any = [];

      UsersCompany?.data?.pages &&
        UsersCompany?.data?.pages[0] !== undefined &&
        UsersCompany?.data?.pages.forEach((page) => {
          if (page !== null)
            allUsersCompany = [...allUsersCompany, ...page.data];
        });
      let newArr = UsersSetting;
      newArr.data = allUsersCompany;

      if (
        UsersCompany?.data?.pages[UsersCompany?.data?.pages.length - 1]?.data
          .length > 0
      ) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setUsersSetting({ ...newArr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UsersCompany?.data?.pages]);

  const changeBranchSearch = (e: any) => {
    let newArr = BranchesSetting;
    newArr.search = e;
    newArr.loadingMore = false;
    newArr.page = 0;
    setBranchesSetting({ ...newArr });
  };

  const handleBranchesScroll = (event: any) => {
    let delta: any;
    let newArr = BranchesSetting;
    let newMin = newArr.page;
    if (event.wheelDelta) {
      delta = event.wheelDelta;
    } else {
      delta = -1 * event.deltaY;
    }
    if (!newArr.loadingMore) {
      if (delta < 0) {
        newMin = newArr.page + 1;
        let length = newArr.data.length;
        if (length !== undefined) {
          if (newMin * 4 >= length) {
            newArr.loadingMore = true;
            newArr.page = newArr.page + 1;
            fetchNextPage();
          }
        }
      } else if (delta > 0 && newArr.page >= 1) {
        newMin = newArr.page - 1;
      }
    }
    newArr.page = newMin;
    setBranchesSetting({ ...newArr });
  };

  const changeCompany = (e: any) => {
    setCompany(e);
    setCompanyPlace(e);
    let newArr = UsersSetting;
    newArr.search = "";
    newArr.loadingMore = false;
    newArr.page = 0;
    setUsersSetting({ ...newArr });
    form.setFieldValue(name[1], "");
    form.validateFields([name[1]]);
  };
  const filterOption = () => {
    return BranchesSetting.data;
  };

  const changeClientSearch = (e: any) => {
    let newArr = UsersSetting;
    newArr.search = e;
    newArr.loadingMore = false;
    newArr.page = 0;
    setUsersSetting({ ...newArr });
  };

  const handleClientScroll = (event: any) => {
    let delta: any;
    let newArr = UsersSetting;
    let newMin = newArr.page;
    if (event.wheelDelta) {
      delta = event.wheelDelta;
    } else {
      delta = -1 * event.deltaY;
    }
    if (!newArr.loadingMore) {
      if (delta < 0) {
        newMin = newArr.page + 1;
        let length = newArr.data.length;
        if (length !== undefined) {
          if (newMin * 4 >= length) {
            newArr.loadingMore = true;
            newArr.page = newArr.page + 1;
            UsersCompany.fetchNextPage();
          }
        }
      } else if (delta > 0 && newArr.page >= 1) {
        newMin = newArr.page - 1;
      }
    }
    newArr.page = newMin;
    setUsersSetting({ ...newArr });
  };

  return (
    <>
      <Form.Item
        name={name[0]}
        required={required}
        rules={rules}
        label={
          <div style={{ display: "flex", gap: "6px" }}>
            <span>{t("branch")}</span>
            <Spin
              spinning={placesLoading || placesFetching}
              size="small"
              indicator={<LoadingOutlined />}
            />
          </div>
        }
      >
        <Select
          showSearch
          disabled={disabled || clientRequest}
          optionFilterProp="children"
          onChange={(e: any) => changeCompany(e)}
          filterOption={filterOption as any}
          onSearch={(e: any) => changeBranchSearch(e)}
          dropdownRender={(menu: any) => (
            <div onWheel={handleBranchesScroll}>{menu}</div>
          )}
          onFocus={() => setPlacesFocuesed(true)}
        >
          {BranchesSetting.data.map((item: any) => (
            <Option key={item.id.toString()}>
              <span style={{ display: "inline-block" }}>
                {i18next.language === "ar"
                  ? item.company.name_ar
                    ? item.company.name_ar
                    : item.company.name_en
                  : item.company.name_en
                  ? item.company.name_en
                  : item.company.name_ar}
              </span>{" "}
              -{" "}
              <span style={{ color: Color.gray8, display: "inline-block" }}>
                {item.name}
              </span>
              {"  "}
              <span style={{ color: Color.gray8, display: "inline-block" }}>
                (
                {item.company.classification.name
                  ? item.company.classification.name
                  : item.company.classification.name_en}
                )
              </span>
            </Option>
          ))}
          {(placesLoading || placesFetching) && loadingMoreSpinner}
        </Select>
      </Form.Item>
      <div style={{ position: "relative" }}>
        <Form.Item
          label={<>{t("client_1")}</>}
          name={name[1]}
          dependencies={[name[0]]}
          rules={[
            {
              required: company !== "0",
              message: t("please_select_requester"),
            },
          ]}
        >
          <Select
            showSearch
            disabled={disabled || !!ticket}
            optionFilterProp="children"
            filterOption={filterOption as any}
            onSearch={(e: any) => changeClientSearch(e)}
            dropdownRender={(menu: any) => (
              <div onWheel={handleClientScroll}>{menu}</div>
            )}
            onSelect={(_, option) => {
              if (option["data-contact"])
                setUserContact({
                  phone:
                    option["data-contact"] &&
                    SplitPhone(option["data-contact"]),
                  title: option["children"],
                });
            }}
            onFocus={() => setUsersFoucesed(true)}
            notFoundContent={t("please_select_company_first")}
          >
            {UsersCompany.isLoading || UsersCompany.isFetching
              ? loadingMoreSpinner
              : UsersSetting.data.map((item) => (
                  <Option
                    style={{ direction: "rtl", textAlign: "right" }}
                    key={item.id.toString()}
                    data-contact={
                      item.phone_number ??
                      item.contacts?.filter(
                        (value) => value.type === "phone"
                      )[0]?.value ??
                      undefined
                    }
                  >
                    {item.full_name}
                  </Option>
                ))}
          </Select>
        </Form.Item>
      </div>
    </>
  );
};

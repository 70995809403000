import { Form, Select } from "antd";
import { Rule } from "antd/es/form";
import { useEffect, useState } from "react";
import { FormInstance } from "antd/lib/form";
import { t } from "i18next";
import useSalesMen from "hooks/query/users/useSalesMen";

const { Option } = Select;
export const AddSalesMan = ({
  name,
  required = false,
  disabled = false,
  rules,
  form,
}: {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  rules?: Rule[];
  form: FormInstance;
}) => {
  const { data } = useSalesMen();

  return (
    <Form.Item
      name={name}
      required={required}
      rules={rules}
      label={<>{t("sales_man")}</>}
    >
      <Select disabled={disabled}>
        {data?.data?.map((item) => (
          <Option key={item.id.toString()}>{item.full_name}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};

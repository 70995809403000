import { useInfiniteQuery, useQuery } from "react-query";
import { FetchServices, FetchServicesList } from "services/categories";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { TicketServiceModel } from "models/ticketServices";
import { useEffect, useState } from "react";
import useDebounce from "hooks/others/useDebounce";

export default function useService(obj: {
  page: number;
  perPage: number;
  id: number;
  search: any;
}) {
  const { newParam: searchParam } = useDebounce(obj.search);

  const queryKey = ["ticketService", obj.perPage, obj.id, searchParam];
  return {
    ...useInfiniteQuery<ApiResult<Array<TicketServiceModel>>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) =>
        await FetchServices(pageParam, obj.perPage, obj.id, obj.search),
      {
        staleTime: 10000,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}

export function useTicketServiceNormal(obj: {
  page: number;
  perPage: number;
  id: number;
}) {
  const queryKey = ["ticketServiceNormal", obj.page, obj.perPage, obj.id];
  return {
    ...useQuery<ApiResult<Array<TicketServiceModel>>, ApiError>(
      queryKey,
      () => FetchServices(obj.page, obj.perPage, obj.id, ""),
      {
        staleTime: 10000,
      }
    ),
  };
}

import React, { useEffect, useRef, useState } from "react";
import Color from "../../../utils/helpers/export-sass-colors";
import { CopyFilled } from "@ant-design/icons";
import { notification } from "antd";
import i18next from "i18next";

export interface MapComponentProps extends google.maps.MapOptions {
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  style?: React.CSSProperties;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  initialMarker?: google.maps.LatLngLiteral;
  line1?: google.maps.LatLngLiteral[];
  line2?: google.maps.LatLngLiteral[];
  disabled?: boolean;
  allowCopy?: boolean;
}

// let marker: google.maps.Marker;
// const setMarker = (e: google.maps.Marker) => {
//   marker = e;
// };

export const MapComponent: React.FC<MapComponentProps> = ({
  center,
  zoom,
  style,
  onClick,
  onIdle,
  children,
  initialMarker,
  disabled,
  allowCopy,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (center?.lat && center.lng) {
      map?.panTo({
        lat: center?.lat!,
        lng: center?.lng!,
      });

      if (zoom) {
        map?.setZoom(zoom!);
      }
    }
  }, [center, zoom]);

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          gestureHandling: "cooperative",
        })
      );
    }
    if (map && props.line1) {
      const line1 = new google.maps.Polygon({
        paths: props.line1,
        strokeColor: Color.red,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: Color.red,
        fillOpacity: 0.35,
      });
      line1.setMap(map);
    }
    if (map && props.line2) {
      const line2 = new google.maps.Polygon({
        paths: props.line2,
        strokeColor: Color.red_2,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: Color.red_2,
        fillOpacity: 0.35,
      });
      line2.setMap(map);
    }
  }, [ref, map, center]);

  useEffect(() => {
    if (map && !disabled) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener("click", (e: any) => {
          if (e && onClick) {
            onClick(e);
          }

          if (marker && marker.setMap) {
            marker.setMap(null);
          }
          setMarker(
            new google.maps.Marker({
              position: e.latLng,
              map,
            })
          );
        });
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle, center]);

  useEffect(() => {
    if (initialMarker?.lat !== undefined && initialMarker?.lng !== undefined) {
      const position = new google.maps.LatLng(
        initialMarker.lat,
        initialMarker.lng
      );
      if (marker) marker.setMap(null);
      setMarker(
        new google.maps.Marker({
          position,
          map,
        })
      );
    }
  }, [map, initialMarker, center]);

  const copyValueToClipboard = (value?: google.maps.LatLng | null) => {
    if (value) {
      navigator.clipboard.writeText(
        `https://www.google.com/maps/search/?api=1&query=${value.lat()}%2C${value.lng()}`
      );
      notification.open({
        type: "success",
        message: (
          <div style={{ fontFamily: "Medium" }}>
            {i18next.language === "ar"
              ? "تم نسخ الموقع الى الحافظة"
              : "Location has been copied to clipboard"}
          </div>
        ),
      });
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div ref={ref} id="map" {...(props as any)} style={style} />
      {allowCopy && (
        <div className="copyLocation">
          <button onClick={() => copyValueToClipboard(marker?.getPosition())}>
            <CopyFilled />
          </button>
        </div>
      )}
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            map,
            disabled,
            onClick(e: google.maps.MapMouseEvent) {
              if (e && onClick) {
                if (marker) marker.setMap(null);
                setMarker(
                  new google.maps.Marker({
                    position: e.latLng,
                    map,
                  })
                );
                onClick(e);
              }
            },
          } as any);
        }
      })}
    </div>
  );
};

import { Button, Form, Input, Modal } from "antd";
import EditCategory from "hooks/query/categories/editCategory";
import { t } from "i18next";
import { CategoryModel } from "models/categories";

import { useEffect, useState } from "react";

interface EditCategoryModalProps {
  id: number;
  item: CategoryModel;
}

const EditTicketServiceModal = ({
  id,
  item: internalItem,
}: EditCategoryModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const editCategory = EditCategory();

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, []);
  const handleClose = () => {
    form.setFieldsValue("");
    setIsOpen(false);
  };
  const onFinish = (e: any) => {
    const formData = new FormData();

    formData.append("name_ar", e.name_ar);
    formData.append("name_en", e.name_en);
    formData.append("_method", "PUT");

    editCategory.mutateAsync({ id, data: formData }).then(() => {
      handleClose();
    });
  };
  return (
    <>
      <Button
        type="link"
        onClick={() => {
          setIsOpen(true);
          form.setFieldsValue("");
        }}
      >
        {t("update")}
      </Button>

      <Modal
        key={"editCategory"}
        title={t("edit_category")}
        open={isOpen}
        width={400}
        centered
        bodyStyle={{ padding: 20 }}
        destroyOnClose
        onCancel={() => handleClose()}
        confirmLoading={editCategory.isLoading}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            name_ar: internalItem?.name_ar,
            name_en: internalItem?.name_en,
          }}
          labelCol={{ span: 24 }}
          validateMessages={{
            required: t("please_input") + " ${label}!",
          }}
        >
          <Form.Item
            name={"name_ar"}
            label={t("arabic_name")}
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"name_en"}
            label={t("english_name")}
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditTicketServiceModal;

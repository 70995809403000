import { ApiError } from "utils/api/models/api-error";
import { ReportModel } from "models/reports/index";
import { useQuery } from "react-query";
import { FetchReports } from "services/reports";
import ApiResult from "utils/api/models/api-result";

export default function useReports(id: number, enabled?: boolean) {
  const queryKey = ["Reports", { id }];
  return {
    ...useQuery<ApiResult<ReportModel[]>, ApiError>(
      queryKey,
      () => FetchReports(id),
      {
        staleTime: Infinity,
        enabled: enabled,
      }
    ),
  };
}

import Form from "antd/es/form";
import ToolTip from "components/tooltip";
import { Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { t } from "i18next";

const { Option } = Select;

export const Priority = ({
  name,
  required = false,
  disabled = false,
  form,
}: {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  form: FormInstance;
}) => {
  return (
    <Form.Item
      label={<>{t("ticket_priority")}</>}
      name={name}
      rules={[
        {
          required: required,
          message: t("please_set_ticket_priority"),
        },
      ]}
      required={required}
    >
      <Select disabled={disabled}>
        <Option key={0}>{t("normal")}</Option>
        <Option key={1}>{t("medium")}</Option>
        <Option key={2}>{t("emergent")}</Option>
      </Select>
    </Form.Item>
  );
};

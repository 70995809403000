import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { UpdateFormalReportAsync } from "services/reports";
import i18next from "i18next";

export default function UpdateFormalReport() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(UpdateFormalReportAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? " تم تحديث التقرير العام"
                : "Final report has been updated successfullys",
          });
          queryClient.invalidateQueries("FormalReport");
          queryClient.invalidateQueries("ticket");
        }
      },
    }),
  };
}

import { hasPermission } from "components/permissionsGate";
import TicketInput from "../forms/inputs";
import { Ticket } from "utils/helpers/static_data";
import { TicketModel } from "models/tickets";
import { FormInstance } from "antd";

interface LocationInputProps {
  ticket?: TicketModel;
  Status: any;
  form: FormInstance<any>;
  companyPlace?: any;
}

const LocationInput = ({
  ticket,
  Status,
  form,
  companyPlace,
}: LocationInputProps) => {
  return (
    <TicketInput.Location
      form={form}
      name={{ location: "location_name", lat: "lat", lon: "lon" }}
      required={true}
      disabled={
        !hasPermission([
          Status === Ticket.create ? "add_tickets" : "edit_tickets",
        ])
      }
      ticket={ticket}
      companyPlace={companyPlace}
    />
  );
};

export default LocationInput;

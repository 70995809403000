import { UserModel } from "models/users/index";
import { useQuery } from "react-query";
import { FetchCurrentUser } from "services/users";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { useAppSelector } from "store";

interface props {
  enabled?: boolean;
}

export default function useCurrentUser({ enabled = true }: props) {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const queryKey = "CurrentUser";
  return {
    ...useQuery<ApiResult<UserModel>, ApiError>(
      queryKey,
      () => FetchCurrentUser(),
      {
        cacheTime: 0,
        staleTime: 10000,
        enabled: isLoggedIn && enabled,
      }
    ),
  };
}

import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { DeleteCategoryAsync } from "services/categories";
import i18next from "i18next";

export default function DeleteCategory() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(DeleteCategoryAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم الحذف بنجاح"
                : "Deleted successfullys",
          });
          queryClient.invalidateQueries("categories-selector");
          queryClient.invalidateQueries("ticketServiceNormal");
        }
      },
    }),
  };
}

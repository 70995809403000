import { useContext, useEffect, useState } from "react";
import { Layout, Drawer, Card } from "antd";
import { SidebarContext } from "state/SideBarContext";
import SidebarMenu from "./SideBarMenu";
import { Link } from "react-router-dom";
import { isClient } from "utils/helpers/check-roles";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import Logo from "assets/images/logo.png";
import { useWidth } from "utils/helpers/use-is-mobile";
import i18next from "i18next";
import NewsModal, { NewsIcon } from "components/Modals/whatsNew";

const { Sider } = Layout;

const Sidebar = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { isMobile } = useWidth();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!isMobile && sidebarToggle) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [isMobile, sidebarToggle]);

  const [isNewsVisible, setIsNewsVisible] = useState(false);
  const openNews = () => {
    setIsNewsVisible(true);
  };

  return (
    <>
      <Sider
        width={270} // Adjust the width as per your theme
        style={{
          background: "#fff", // Adjust background as per your theme
          height: "100%",
          position: "fixed",
          zIndex: 10,
          transition: "all 0.3s ease",
          transform: visible
            ? "translateX(0)"
            : i18next.language === "ar"
            ? "translateX(100%)"
            : "translateX(-100%)",
          opacity: visible ? 1 : 0,
        }}
      >
        <Card
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "80px",
              alignItems: "center",
              margin: "0 16px",
              borderBottom: "1px solid #e8e8e8",
              gap: "30px",
            }}
          >
            <Link
              to={{
                pathname: isClient(UserData.Decrypt()?.role_id!)
                  ? "/c-tickets"
                  : "/",
              }}
            >
              <img className="logo" src={Logo} alt="" />
            </Link>
            <div className="brand" style={{ position: "relative" }}>
              <NewsIcon onClick={() => openNews()} />
            </div>
          </div>
          <div style={{ height: "calc(100% - 80px)" }}>
            <div style={{ paddingTop: "8px" }}>
              <SidebarMenu />
            </div>
          </div>
        </Card>
      </Sider>
      {isMobile && (
        <Drawer
          visible={sidebarToggle}
          onClose={closeSidebar}
          placement={document.dir === "rtl" ? "right" : "left"}
          bodyStyle={{ padding: 0 }}
        >
          <Card
            style={{
              height: "100%",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "80px",
                alignItems: "center",
                margin: "0 16px",
                borderBottom: "1px solid #e8e8e8", // Adjust border color as per your theme
                gap: "30px",
              }}
            >
              <Link
                to={{
                  pathname: isClient(UserData.Decrypt()?.role_id!)
                    ? "/c-tickets"
                    : "/",
                }}
              >
                <img className="logo" src={Logo} alt="" />
              </Link>
              <div className="brand" style={{ position: "relative" }}>
                <NewsIcon onClick={() => openNews()} />
              </div>
            </div>

            <SidebarMenu />
          </Card>
        </Drawer>
      )}
      <NewsModal modal={isNewsVisible} close={() => setIsNewsVisible(false)} />
    </>
  );
};

export default Sidebar;

import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { hasPermission } from "components/permissionsGate";
import AddCategory from "hooks/query/categories/addCategory";
import { t } from "i18next";

import { useEffect, useState } from "react";

const AddTicketServiceModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const addCategory = AddCategory();

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, []);
  const handleClose = () => {
    form.setFieldsValue("");
    setIsOpen(false);
  };
  const onFinish = (e: any) => {
    const formData = new FormData();

    formData.append("name_ar", e.name_ar);
    formData.append("name_en", e.name_en);

    addCategory.mutateAsync({ data: formData }).then(() => {
      handleClose();
    });
  };
  return (
    <>
      {hasPermission(["add_categories"]) && (
        <Button
          type="primary"
          onClick={() => {
            setIsOpen(true);
          }}
          icon={<PlusOutlined />}
        >
          {t("add")}
        </Button>
      )}
      <Modal
        key={"addCategory"}
        title={t("add_category")}
        open={isOpen}
        width={400}
        centered
        bodyStyle={{ padding: 20 }}
        destroyOnClose
        onCancel={() => handleClose()}
        confirmLoading={addCategory.isLoading}
        onOk={() => {
          form.submit();
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{}}
          labelCol={{ span: 24 }}
          validateMessages={{
            required: t("please_input") + " ${label}!",
          }}
        >
          <Form.Item
            name={"name_ar"}
            label={t("arabic_name")}
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"name_en"}
            label={t("english_name")}
            rules={[{ required: true }]}
            required
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddTicketServiceModal;

import { Form, Select } from "antd";
import { Rule } from "antd/es/form";
import { useCategoriesList } from "hooks/query/categories/useCategories";
import React, { useState } from "react";
import { FormInstance } from "antd/lib/form";
import { loadingMoreSpinner } from "utils/ui/ScrollLoading";
import { t } from "i18next";

const { Option } = Select;

export const Category = ({
  name,
  required = false,
  disabled = false,
  rules,
  setCategory,
}: {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  rules: Rule[];
  setCategory: React.Dispatch<React.SetStateAction<number>>;
  form: FormInstance;
}) => {
  const [enabled, setEnabled] = useState(false);
  const { data, isLoading, isFetching } = useCategoriesList(enabled);

  return (
    <Form.Item
      name={name}
      required={required}
      rules={rules}
      label={t("category_name")}
    >
      <Select
        onFocus={() => setEnabled(true)}
        disabled={disabled}
        onSelect={setCategory}
      >
        {isLoading
          ? loadingMoreSpinner
          : data?.data?.map((item) => (
              <Option key={item.id.toString()}>{item.name}</Option>
            ))}
      </Select>
    </Form.Item>
  );
};

import { useInfiniteQuery, useQuery } from "react-query";
import { FetchTechnicals, FetchTechnicalsUsers } from "services/users";
import ApiResult from "utils/api/models/api-result";
import { UserModel } from "models/users";
import { ApiError } from "utils/api/models/api-error";
import useDebounce from "hooks/others/useDebounce";

export default function useTechnicalsUsers(obj: {
  page: number;
  search: any;
  enabled?: boolean;
}) {
  const { newParam: searchParam } = useDebounce(obj.search);

  const queryKey = ["TechnicalsUsers", searchParam];
  return {
    ...useInfiniteQuery<ApiResult<UserModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) => FetchTechnicals(pageParam, obj.search),
      {
        enabled: obj.enabled,
        staleTime: 10000,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}
export function useUsersTech(page: any) {
  const queryKey = ["userstech", page];
  return {
    ...useQuery<ApiResult<UserModel[]>>(
      queryKey,
      () => FetchTechnicalsUsers(page, ""),
      {
        refetchOnWindowFocus: false,
        staleTime: 30000,
      }
    ),
  };
}

import { useQuery } from "react-query";
import { FetchSiblingPlacesAsync } from "services/places";
import ApiResult from "utils/api/models/api-result";
import { CompanyModel } from "models/companies";
import { ApiError } from "utils/api/models/api-error";

export default function usePlacesSibling(id: any) {
  const queryKey = ["SiblingPlaces", id];
  return {
    ...useQuery<ApiResult<CompanyModel>, ApiError>(
      queryKey,
      () => FetchSiblingPlacesAsync(id),
      {
        refetchOnWindowFocus: false,
        // staleTime: 10000,
      }
    ),
  };
}

import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { DeviceUUID } from "device-uuid";
import { Token } from "utils/helpers/encrypt-decrypt-user";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyA5hg7Y4XUPj6revUrIIWcsqjzl2xoJVFk",
  authDomain: "izon-63150.firebaseapp.com",
  projectId: "izon-63150",
  storageBucket: "izon-63150.appspot.com",
  messagingSenderId: "111888808443",
  appId: "1:111888808443:web:32704579578e7d18aab35e",
  measurementId: "G-2RMKH4F87B",
});
if (firebaseApp) {
}

/**
 * disable for localhost
 */
export const messaging = getMessaging(firebaseApp);
// export const messaging = "";

export const getTokenAsync = () => {
  return getToken(messaging, {
    vapidKey:
      "BMEj4K9MMjjTAn2elTXUQUQLqBVg25m4WNRw8BQky_2KTGVUROOdYa5ML6V5bVP_OmHuTxH369GMvTGo5JYpxFs",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        var uuid = new DeviceUUID().get();
        if (Token.Get() !== null)
          axios.post(
            process.env.REACT_APP_API_URL + "api/add-firebase-token",
            {
              firebase_token: currentToken,
              device_id: uuid,
              platform: "Web",
              version: `${process.env.REACT_APP_VERSION}`,
            },
            {
              headers: {
                Authorization: `Bearer ${Token.Get()}`,
              },
            }
          );
        return currentToken;
      }
    })
    .catch((err) => {
      console.log("Error firebase", err);
      // catch error while creating clieernt token
    });
};

import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";
import { TechnicanTicketReport } from "models/reports";
import { TechnicanStandByRequestModel } from "models/technicians";

class TechnicansService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async FetchTechnicansScheduledAppointments(
    startDate: string,
    endDate: string,
    page: number,
    perPage: number
  ) {
    return this.get(
      `/api/getSchedules?page=${page}&items_per_page=${perPage}&fromTime=${startDate}&toTime=${endDate}`
    );
  }

  public async FetchTechnicansCurrentStatus() {
    return this.get(`/api/getTechnicalStandByStatus`);
  }

  public async PostTechnicianStandByStatus(data: TechnicanStandByRequestModel) {
    const isStandBy =
      JSON.parse(localStorage.getItem("technicianStatus")!) == "stand_by_on";
    return this.post(`/api/setTechnicalStandByStatus`, {
      ...data,
      status: isStandBy ? "off" : "on",
    });
  }

  public async FetchTechnicansDeadlines(
    date: string,
    page: number,
    perPage: number
  ) {
    return this.get(
      `/api/tickets-deadline?page=${page}&items_per_page=${perPage}&filter[deadline]=${date}`
    );
  }
}

const technicansService = new TechnicansService();

export const GetTechnicansScheduledAppointments = async (
  startDate: string,
  endDate: string,
  page: number,
  perPage: number
) => {
  const result: any =
    await technicansService.FetchTechnicansScheduledAppointments(
      startDate,
      endDate,
      page,
      perPage
    );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const GetTechnicianCurrentStatus = async () => {
  const result: any = await technicansService.FetchTechnicansCurrentStatus();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const SetTechnicianStandByStatus = async (
  data: TechnicanStandByRequestModel
) => {
  const result: any = await technicansService.PostTechnicianStandByStatus(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const GetTechnicansDeadlines = async (
  date: string,
  page: number,
  perPage: number
) => {
  const result: any = await technicansService.FetchTechnicansDeadlines(
    date,
    page,
    perPage
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

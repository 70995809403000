import { Table } from "antd";

import "../table.scss";
import ExpandIcon from "../expandIcon";

interface expandedRowRenderFn {
  (record: any): any;
}

interface expandableTableProps {
  columns: any;
  data: any;
  expandedRowRender: expandedRowRenderFn;
  expandedRowKeys?: any;
  setExpandedRowKey?: any;
  setExpandedRowKeys?: any;
  isLoading?: any;
  setEnabled?: any;
  setId?: any;
  multi?: boolean;
  rowSelection?: any;
  rowKey?: any;
}

const ExpandableTable = ({
  columns,
  data,
  expandedRowRender,
  expandedRowKeys,
  setExpandedRowKey,
  setExpandedRowKeys,
  isLoading,
  setEnabled,
  setId,
  multi,
  rowSelection,
  rowKey,
}: expandableTableProps) => {
  const expandIcon = ({ onExpand, record, expanded }: any) => (
    <ExpandIcon
      isExpanded={expanded}
      onClick={(e: any) => onExpand(record, e)}
    />
  );

  const onExpandRow = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.id);
    } else {
      setExpandedRowKey(null);
    }
    setId(record.id);
    setEnabled(true);
  };

  const onExpandMultiRows = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.id]);
    } else {
      const indexKeyToRemove = expandedRowKeys.indexOf(record.id);

      if (indexKeyToRemove !== -1) {
        expandedRowKeys.splice(indexKeyToRemove, 1);
      }
    }
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowSelection={rowSelection}
      expandable={{
        expandedRowRender,
        expandIcon,
        onExpand: multi ? onExpandMultiRows : onExpandRow,
        expandRowByClick: true,
        expandedRowKeys,
      }}
      loading={isLoading}
      rowClassName={(_, index) => {
        const keys: number[] = expandedRowKeys.filter(
          (key: number) => index + 1 == key
        );
        return keys.includes(index + 1) ? "expanded-row" : "";
      }}
      rowKey={rowKey}
    />
  );
};

export default ExpandableTable;

import { useAppDispatch } from "store";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { DeviceUUID } from "device-uuid";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { LOGOUT } from "actions/types";
import { checkAuth } from "state";
import { Token, UserData } from "utils/helpers/encrypt-decrypt-user";
import { useNavigate } from "react-router-dom";
import { deleteToken } from "firebase/messaging";
import { messaging } from "firebaseInit";

const logout = async () => {
  let config = {
    headers: { Authorization: `Bearer ${Token.Get()}` },
  };
  var uuid = new DeviceUUID().get();
  const URL = `${process.env.REACT_APP_API_URL}api/logout`;
  return await axios
    .post(URL, { device_id: uuid, _method: "delete" }, { ...config })
    .then((response) => {
      Token.Remove();
      UserData.Remove();
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 403) {
        notification.open({
          type: "error",
          message: error.message,
        });
        return true;
      } else if (error.response.status === 401) {
        return true;
      } else {
        // notification.open({
        //   type: "error",
        //   message: error.message,
        //   duration: 300,
        // });
      }
      Token.Remove();
      UserData.Remove();
    });
};

export default function useLogout() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  return {
    ...useMutation(logout, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          dispatch(
            checkAuth({
              type: LOGOUT,
            })
          );

          deleteToken(messaging);

          navigate({
            pathname: "/login",
          });
          queryClient.clear();
        }
      },
      onError: async (err: Error) => {
        dispatch(checkAuth({ type: err.message }));
      },
    }),
  };
}

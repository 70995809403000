import { FormInstance } from "antd";
import TicketInput from "../forms/inputs";
import { hasPermission } from "components/permissionsGate";
import { Ticket } from "utils/helpers/static_data";

interface PriorityInputProps {
  form: FormInstance;
  Status: any;
}

const PriorityInput = ({ form, Status }: PriorityInputProps) => {
  return (
    <TicketInput.Priority
      name={"priority"}
      required
      disabled={
        !hasPermission([
          Status === Ticket.create ? "add_tickets" : "edit_tickets",
        ])
      }
      form={form}
    />
  );
};

export default PriorityInput;

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form } from "antd";
import CountDown from "components/countDown";
import OtpInput from "react-otp-input";
import Color from "utils/helpers/export-sass-colors";
import { useCallback, useEffect, useState } from "react";
import SendOtp from "hooks/query/users/SendOtp";
import VerifyOtp from "hooks/query/users/VerifyOtp";
import { LoadingOutlined } from "@ant-design/icons";
import { Token } from "utils/helpers/encrypt-decrypt-user";
import { useNavigate } from "react-router-dom";
import i18next, { t } from "i18next";

const OTPForm = ({
  email,
  setOTP,
  setStatus,
}: {
  email: string;
  setOTP?: React.Dispatch<React.SetStateAction<boolean>>;
  setStatus?: React.Dispatch<React.SetStateAction<"register" | "otp">>;
}) => {
  const [OTPValue, setOTPValue] = useState<string>("");
  const [countDown, setCountDown] = useState("120");
  const [timer, setTimer] = useState("");

  const verify = VerifyOtp();
  const send = SendOtp();
  const navigate = useNavigate();

  const handleVerifyOTP = () => {
    if (email && OTPValue) {
      verify
        .mutateAsync({ phone_number: email, otp: OTPValue })
        .then((data: any) => {
          Token.Set(data.token);
          if (setOTP) setOTP(false);
          else if (setStatus)
            navigate({
              pathname: "/login",
            });
        });
    }
  };

  const handleSendOTPagain = useCallback(() => {
    if (email) {
      send
        .mutateAsync({ phone_number: email, signature: "3vNkovJWhT" })
        .then((data: any) => {
          setTimer("");
          setCountDown(data.data.expires_after);
          if (setOTP) setOTP(true);
          else if (setStatus) setStatus("otp");
        });
    }
  }, []);

  useEffect(() => {
    handleSendOTPagain();
  }, []);

  return (
    <Form onFinish={handleVerifyOTP}>
      <Form.Item
        name={"otp"}
        rules={[
          {
            required: true,
            message:
              i18next.language === "ar"
                ? "يرجى ادخال الرمز!"
                : "Please input verification code",
          },
        ]}
      >
        <OtpInput
          value={OTPValue}
          onChange={setOTPValue}
          numInputs={6}
          isInputNum={true}
          containerStyle={{
            justifyContent: "center",
            marginBottom: 20,
            direction: "ltr",
          }}
          className="OTPInput"
          isDisabled={timer === "00:00" || send.isLoading}
          disabledStyle={{
            cursor: "not-allowed",
            opacity: "0.4",
          }}
        />
      </Form.Item>
      {timer === "00:00" ? (
        <div>
          <div className="lable">{t("code_expired")}:</div>
          <div
            style={{
              padding: "10px",
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: "transparent",
                border: "none",
                borderRadius: "30px",
                color: Color.blue,
              }}
              onClick={() => {
                handleSendOTPagain();
              }}
              loading={send.isLoading}
            >
              {t("resende_code")}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="lable">{t("code_time_expires")}:</div>
          <div
            style={{
              padding: "10px",
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!send.isLoading && (
              <CountDown time={countDown} getTimer={(e: any) => setTimer(e)} />
            )}
            {send.isLoading && (
              <LoadingOutlined
                style={{ fontSize: 40, color: Color.blue }}
                spin={send.isLoading}
              />
            )}
          </div>
        </div>
      )}
      <Form.Item>
        <Button
          className="submitButton"
          type="primary"
          htmlType="submit"
          loading={verify.isLoading}
          disabled={timer === "00:00" || send.isLoading}
          shape="round"
        >
          {t("comfirm")}
        </Button>
      </Form.Item>
    </Form>
  );
};
export default OTPForm;

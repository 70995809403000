import { Button, Form, Input, notification } from "antd";
import { arabicLetter, englishLetter } from "utils/helpers/regex";
import InputRule from "utils/helpers/input-roles";
import { useQueryClient } from "react-query";
import { useState } from "react";
import useRegister from "hooks/auth/register";
import InputPhone from "components/inputPhone";
import { t } from "i18next";

const RegisterForm = ({
  setStatus,
  setEmail,
}: {
  setStatus: React.Dispatch<React.SetStateAction<"register" | "otp">>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const registerUser = useRegister();

  const registerSubmit = (e: any) => {
    console.log(e);
    let Email: string = e.phone_number.phone;
    let letter: string = e.phone_number.phone.charAt(0);
    if (letter === "0") {
      Email = e.phone_number.slice(1);
    }
    Email = `+${e.phone_number.code + Email}`;
    setEmail(Email);
    if (e.password !== e.confirm) {
      notification.open({
        message: "Error",
        description: t("invalid_confirm_password"),
      });
    } else {
      setIsLoading(true);
      registerUser
        .mutateAsync({
          full_name: e.full_name,
          phone_number: Email,
          job_title_id: "38",
          password: e.password,
          password_confirmation: e.confirm,
          signature: "3vNkovJWhT",
        })
        .then(() => {
          queryClient.invalidateQueries("NotificationsNumber");
          queryClient.invalidateQueries("tickets");
          setStatus("otp");
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  return (
    <Form onFinish={(e) => registerSubmit(e)}>
      <Form.Item
        name={"full_name"}
        rules={[
          { required: true, message: t("required_field") },
          {
            validator(_, value) {
              if (value && !(englishLetter(value) || arabicLetter(value)))
                return Promise.reject(new Error("الاسم غير صالح!"));
              else {
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        <Input placeholder={t("name")} type="text" className="textInput" />
      </Form.Item>
      <Form.Item
        name={"phone_number"}
        rules={[
          {
            validator(rule, value) {
              if (!value.phone || value.phone.length < 1) {
                return Promise.reject(new Error(t("phonenumber_required")));
              } else if (value.phone.length >= 17) {
                return Promise.reject(new Error(t("invalid_phone_number")));
              } else if (value.phone.length < 6) {
                return Promise.reject(new Error(t("invalid_password")));
              }
              return Promise.resolve();
            },
          },
        ]}
        initialValue={{ short: "SY", code: 963 }}
      >
        <InputPhone className="phoneNumberInput" />
      </Form.Item>
      <Form.Item
        name={"password"}
        rules={[
          { required: true, message: t("password_required") },
          ...InputRule.Password,
        ]}
      >
        <Input.Password
          className="passwordInput"
          placeholder={t("password")}
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item
        name={"confirm"}
        dependencies={["password"]}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("invalid_confirm_password")));
            },
          }),
        ]}
      >
        <Input.Password
          className="passwordInput"
          placeholder={t("confirm_password")}
          autoComplete="off"
        />
      </Form.Item>
      <Form.Item>
        <Button
          className="submitButton"
          htmlType="submit"
          type="primary"
          loading={isLoading}
          shape="round"
        >
          {t("create_account")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterForm;

import { useState, useEffect } from 'react';

const useNotificationPermission = () => {
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);

  useEffect(() => {
    const detectNotificationPermission = () => {
      if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            setIsNotificationEnabled(true);
          } else {
            setIsNotificationEnabled(false);
          }
        });
      } else {
        console.log('Notifications not supported');
      }
    };

    detectNotificationPermission();
  }, []);

  return isNotificationEnabled;
};

export default useNotificationPermission;

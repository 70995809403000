import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { VerifyOtpAsync } from "services/users";
import i18next from "i18next";

export default function VerifyOtp() {
  return {
    ...useMutation(VerifyOtpAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم تأكيد حسابك"
                : "Your account has been confirmed",
          });
        }
      },
    }),
  };
}

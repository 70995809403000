import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class WorkplaceService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(): Promise<ApiResponse<any>> {
    return this.get(`/api/workplaces`);
  }
}

const workpalcesService = new WorkplaceService();

export const FetchWorkPlaces = async () => {
  const result: any = await workpalcesService.Fetch();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

import { FetchAllContacts } from "services/contacts/index";
import { useQuery } from "react-query";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { ContactModel } from "models/users";

export default function useAllContacts(id: any) {
  const queryKey = ["Contacts", id];
  return {
    ...useQuery<ApiResult<ContactModel[]>, ApiError>(
      queryKey,
      () => FetchAllContacts(id),
      {
        refetchOnWindowFocus: false,
        staleTime: 10000,
        cacheTime: 0,
      }
    ),
  };
}

import Color from "./export-sass-colors";

export const StatusColor = (id: number) => {
  switch (id) {
    case 0:
      return Color.pending;
    case 1:
      return Color.pending;
    case 9:
      return Color.priced;
    case 10:
      return Color.paid;
    case 11:
      return Color.partial_paid;
    case 2:
      return Color.active;
    case 3:
      return Color.done;
    case 4:
      return Color.approved;
    case 5:
      return Color.denied;
    default:
      return Color.black;
  }
};

import { Form, Select, Spin } from "antd";
import { FormInstance, Rule } from "antd/es/form";
import { useEffect, useState } from "react";

import { settingInterface } from "DTO/interfaces";
import { TicketServiceModel } from "models/ticketServices";
import useService from "hooks/query/categories/ticketService/useService";
import { LoadingOutlined } from "@ant-design/icons";
import { t } from "i18next";

const { Option } = Select;

export const Service = ({
  name,
  required = false,
  disabled = false,
  rules,
  form,
  category_id = 0,
}: {
  name: string;
  required?: boolean;
  disabled?: boolean;
  rules: Rule[];
  form: FormInstance;
  category_id: number | undefined;
}) => {
  const [serviceSettings, setServiceSettings] = useState<
    settingInterface<TicketServiceModel[]>
  >({
    page: 0,
    perPage: 12,
    search: "",
    loadingMore: false,
    data: [],
  });

  const { data, status, fetchNextPage, isLoading, isFetching } = useService({
    page: serviceSettings.page,
    perPage: serviceSettings.perPage,
    id: category_id,
    search: serviceSettings.search,
  });

  const changeSearch = (e: any) => {
    let newArr = serviceSettings;
    newArr.page = 0;
    newArr.loadingMore = false;
    newArr.search = e;
    setServiceSettings({ ...newArr });
  };

  useEffect(() => {
    if (status === "success") {
      let allTicketServices: any = [];

      data?.pages &&
        data?.pages[0] !== undefined &&
        data?.pages.forEach((page) => {
          if (page !== null)
            allTicketServices = [...allTicketServices, ...page.data];
        });
      let newArr = serviceSettings;
      newArr.data = allTicketServices;

      if (data?.pages[data?.pages.length - 1]?.data.length > 0) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setServiceSettings({ ...newArr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages]);

  const handleScroll = (event: any) => {
    let delta: any;
    let newArr = serviceSettings;
    let newMin = newArr.page;
    if (event.wheelDelta) {
      delta = event.wheelDelta;
    } else {
      delta = -1 * event.deltaY;
    }
    if (!newArr.loadingMore) {
      if (delta < 0) {
        newMin = newArr.page + 1;
        let length = newArr.data.length;
        if (length !== undefined) {
          if (newMin * 4 >= length) {
            newArr.loadingMore = true;
            newArr.page = newArr.page + 1;
            fetchNextPage();
          }
        }
      } else if (delta > 0 && newArr.page >= 1) {
        newMin = newArr.page - 1;
      }
    }
    newArr.page = newMin;
    setServiceSettings({ ...newArr });
  };
  const filterOption = (option: any, inputValue: any) => {
    return serviceSettings.data;
  };

  const serviceChange = (e: any) => {
    return "";
  };

  return (
    <Form.Item
      name={name}
      required={required}
      rules={rules}
      label={
        <div style={{ display: "flex", gap: "6px" }}>
          <span>{t("sub_category_name")}</span>
          <Spin
            spinning={isLoading || isFetching}
            size="small"
            indicator={<LoadingOutlined />}
          />
        </div>
      }
    >
      <Select
        showSearch
        filterOption={filterOption as any}
        disabled={disabled}
        dropdownRender={(menu: any) => <div onWheel={handleScroll}>{menu}</div>}
        optionFilterProp="children"
        onSearch={changeSearch}
        onChange={(e) => serviceChange(e)}
        notFoundContent={<span>{t("please_select_category_first")}</span>}
      >
        {serviceSettings.data.map((item) => (
          <>
            <Option key={item.id.toString()}>
              <span>{item?.name}</span>
              {item.cost !== null && item.cost !== 0 && (
                <span
                  style={{
                    color: "#999",
                    fontSize: "12px",
                    display: "block",
                  }}
                >
                  {t("cost_per_hour") + item.cost.toLocaleString("en-US")}
                </span>
              )}
            </Option>
          </>
        ))}
        {(isLoading || isFetching) && (
          <Option
            disabled={true}
            style={{ textAlign: "center", cursor: "default" }}
          >
            <Spin indicator={<LoadingOutlined />} spinning={true} />
          </Option>
        )}
      </Select>
    </Form.Item>
  );
};

import { useQuery } from "react-query";
import { FetchFormalReport } from "services/reports";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { FormalReportModel } from "models/reports";

export default function useFormalReport(id: number) {
  const queryKey = ["FormalReport", id];
  return {
    ...useQuery<ApiResult<FormalReportModel>, ApiError>(
      queryKey,
      () => FetchFormalReport(id),
      {
        refetchOnWindowFocus: false,
        cacheTime: 0,
        staleTime: 10000,
      }
    ),
  };
}

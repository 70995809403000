import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class NotificationsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(page: any, unread: boolean): Promise<ApiResponse<any>> {
    return this.get(
      `/api/notifications?items_per_page=10&page=${page}${
        unread ? `&filter[is_read]=0` : ``
      }`
    );
  }
  public async FetchNumber(): Promise<ApiResponse<any>> {
    return this.get(`/api/get-notifications-number`);
  }

  public async ReadAll(): Promise<ApiResponse<any>> {
    return this.post(`/api/read-all-notifications`);
  }

  public async ReadOne(id: number): Promise<ApiResponse<any>> {
    return this.post(`/api/read-one-notification/${id}`);
  }
}

const notificationsService = new NotificationsService();

export const FetchNotifications = async (page: any, unread: boolean) => {
  const result: any = await notificationsService.Fetch(page, unread);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchNotificationsNumber = async () => {
  const result: any = await notificationsService.FetchNumber();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const ReadAll = async () => {
  const result: any = await notificationsService.ReadAll();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const ReadOne = async (id: number) => {
  const result: any = await notificationsService.ReadOne(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

import { useQuery } from "react-query";
import { FetchNotificationsNumber } from "services/notifications";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { NotificationModel, NotificationNumber } from "models/notification";

export default function useNotificationsNumber() {
  const queryKey = "NotificationsNumber";
  return {
    ...useQuery<ApiResult<NotificationNumber>, ApiError>(
      queryKey,
      async () => await FetchNotificationsNumber(),
      {
        // enabled: isLoggedIn,
        staleTime: 10000,
      }
    ),
  };
}

import { AxiosRequestConfig } from "axios";
import { TicketServiceModel } from "models/ticketServices";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class ServicesService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async FetchServiceByLevel(
    id: any,
    page: any,
    perPage: any
  ): Promise<ApiResponse<TicketServiceModel[]>> {
    return this.get(
      `/api/get-services/level/${id}?page=${page}&items_per_page=${perPage}`
    );
  }
  public async FetchServiceByCategory(
    id: any,
    page: any,
    perPage: any
  ): Promise<ApiResponse<TicketServiceModel[]>> {
    return this.get(
      `/api/get-services/category/${id}?page=${page}&items_per_page=${perPage}&show_type=true`
    );
  }
  public async UpdateService(id: number, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/services/${id}`, data);
  }
  public async DeleteService(id: number): Promise<ApiResponse<any>> {
    return this.delete(`/api/services/${id}`);
  }
  public async AddService(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/services`, data);
  }
}

const servicesService = new ServicesService();

export const FetchServicesByLevel = async (
  id: number,
  page: number,
  perPage: number
) => {
  const result: any = await servicesService.FetchServiceByLevel(
    id,
    page,
    perPage
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchServicesByCategory = async (
  id: number,
  page: number,
  perPage: number
) => {
  const result: any = await servicesService.FetchServiceByCategory(
    id,
    page,
    perPage
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const UpdateServiceAsync = async ({
  data,
  id,
}: {
  id: number;
  data: any;
}) => {
  const result: any = await servicesService.UpdateService(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const DeleteServiceAsync = async ({ id }: { id: number }) => {
  const result: any = await servicesService.DeleteService(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const AddServiceAsync = async ({ data }: { data: any }) => {
  const result: any = await servicesService.AddService(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

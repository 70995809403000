import { FC, ReactNode, useContext } from "react";
import { Layout } from "antd";
import Sidebar from "./SideBar";
import Content from "./Content";
import i18next from "i18next";
import { useWidth } from "utils/helpers/use-is-mobile";
import { SidebarContext } from "state/SideBarContext";
import Footer from "./Footer";

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const { isMobile } = useWidth();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout
        style={{
          flexDirection: "column",
          marginLeft: !sidebarToggle
            ? 0
            : isMobile
            ? 0
            : i18next.language === "ar"
            ? 10
            : 270,
          marginRight: !sidebarToggle
            ? 0
            : isMobile
            ? 0
            : i18next.language === "ar"
            ? 270
            : 10,
        }}
      >
        <Content />
        <Footer />
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;

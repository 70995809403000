import { FetchUserPlaces } from "services/places/index";
import { useQuery } from "react-query";
import ApiResult from "utils/api/models/api-result";
import { PlaceModel } from "models/companies";
import { ApiError } from "utils/api/models/api-error";

export default function useUserPlaces(enable: boolean) {
  const queryKey = ["UserPlaces"];
  return {
    ...useQuery<ApiResult<PlaceModel[]>, ApiError>(
      queryKey,
      () => FetchUserPlaces(),
      {
        enabled: enable,
        staleTime: 10000,
      }
    ),
  };
}

import { useInfiniteQuery } from "react-query";
import { FetchAllCompanyPlaces } from "services/places";
import ApiResult from "utils/api/models/api-result";
import { PlaceModel } from "models/companies";
import { ApiError } from "utils/api/models/api-error";
import useDebounce from "hooks/others/useDebounce";

export default function useAllPlaces(obj: {
  page: any;
  perPage: any;
  search: any;
  enabled: boolean;
  companyId?: any;
}) {
  const { newParam: searchParam } = useDebounce(obj.search);

  const queryKey = ["usersAllPlaces", obj.perPage, searchParam, obj.companyId];
  return {
    ...useInfiniteQuery<ApiResult<PlaceModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) =>
        await FetchAllCompanyPlaces(
          pageParam,
          obj.perPage,
          obj.search,
          obj.companyId
        ),
      {
        refetchOnWindowFocus: false,
        staleTime: 15000,
        cacheTime: 15000,
        enabled: !!obj.enabled,
        keepPreviousData: true,
        getNextPageParam: (lastPage) => {
          return lastPage?.meta?.currentPage! + 1 ?? false;
        },
      }
    ),
  };
}

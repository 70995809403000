import { Form, FormInstance, Select } from "antd";
import ToolTip from "components/tooltip";
import { Roles, Ticket } from "utils/helpers/static_data";
import { filters } from "components/filters/ticketsFillters";
import { TicketModel } from "models/tickets";
import { useEffect, useState } from "react";
import useTechnicalsUsers from "hooks/query/users/useTechnicalsUsers";
import { settingInterface } from "DTO/interfaces";
import { UserModel } from "models/users";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { useParams } from "react-router-dom";
import { loadingMoreSpinner } from "utils/ui/ScrollLoading";
import i18next, { t } from "i18next";

const { Option } = Select;

export const Assignments = ({
  Status,
  ticket,
  form,
  disabled = false,
}: {
  Status: filters["Status"];
  ticket?: TicketModel;
  form: FormInstance;
  disabled: boolean;
}) => {
  const [LeaderId, setLeaderId] = useState<any>(0);
  const [techsSetting, setTechsSetting] = useState<
    settingInterface<UserModel[]>
  >({
    page: 0,
    perPage: 0,
    loadingMore: false,
    data: [],
    search: "",
  });

  const { id } = useParams();

  const Users = useTechnicalsUsers({
    page: techsSetting.page,
    search: techsSetting.search,
    enabled: id !== "0",
  });

  const changeTechSearch = (e: any) => {
    let newArr = techsSetting;
    newArr.search = e;
    newArr.loadingMore = false;
    newArr.page = 0;
    setTechsSetting({ ...newArr });
  };

  const handleTechScroll = (event: any) => {
    let delta: any;
    let newArr = techsSetting;
    let newMin = newArr.page;
    if (event.wheelDelta) {
      delta = event.wheelDelta;
    } else {
      delta = -1 * event.deltaY;
    }
    if (!newArr.loadingMore) {
      if (delta < 0) {
        newMin = newArr.page + 1;
        let length = newArr.data.length;
        if (length !== undefined) {
          if (newMin * 4 >= length) {
            newArr.loadingMore = true;
            newArr.page = newArr.page + 1;
            Users.fetchNextPage();
          }
        }
      } else if (delta > 0 && newArr.page >= 1) {
        newMin = newArr.page - 1;
      }
    }
    newArr.page = newMin;
    setTechsSetting({ ...newArr });
  };

  useEffect(() => {
    if (Users.status === "success") {
      let allUsers: any = [];

      Users?.data?.pages &&
        Users?.data?.pages[0] !== undefined &&
        Users?.data?.pages.forEach((page) => {
          if (page !== null) allUsers = [...allUsers, ...page.data];
        });
      let newArr = techsSetting;
      newArr.data = allUsers;

      if (Users?.data?.pages[Users?.data?.pages.length - 1]?.data.length > 0) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setTechsSetting({ ...newArr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Users?.data?.pages]);

  useEffect(() => {
    if (ticket) {
      setLeaderId(ticket?.leader?.id);
    }
  }, [ticket]);

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  return (
    <>
      <Form.Item
        label={
          <ToolTip
            content={
              i18next.language === "ar"
                ? "الشخص المسؤول عن المهمة"
                : "The responsible of report"
            }
          >
            {t("ticket_leader")}
          </ToolTip>
        }
        name="leader"
        rules={[
          {
            required:
              Status === Ticket.priced && User?.role_id !== Roles.technical,
            message: t("please_select_ticket_leader"),
          },
        ]}
      >
        <Select
          disabled={Status === Ticket.create || disabled}
          dropdownRender={(menu: any) => (
            <div onWheel={handleTechScroll}>{menu}</div>
          )}
          filterOption={techsSetting.data as any}
          onSearch={(e: any) => changeTechSearch(e)}
          onBlur={(e: any) => changeTechSearch("")}
          onChange={(e: any) => {
            setLeaderId(parseInt(e));
            let techs = form.getFieldValue("technicians") as any[];
            techs = techs.map((v) => v?.value ?? v);
            if (techs.includes(e)) {
              const index = techs.findIndex((v) => v === e);
              techs.splice(index, 1);
              form.setFieldValue("technicians", [...techs]);
            }
          }}
          showSearch
          optionFilterProp="children"
        >
          {techsSetting?.data.map((tech) => (
            <Option key={tech.id.toString()}>{tech.full_name}</Option>
          ))}
          {(Users.isLoading || Users.isFetching) && loadingMoreSpinner}
        </Select>
      </Form.Item>
      <Form.Item
        label={<>{t("technicals")}</>}
        dependencies={["leader"]}
        name="technicians"
      >
        <Select
          disabled={
            Status === Ticket.create ||
            !form.getFieldValue("leader") ||
            disabled
          }
          dropdownRender={(menu: any) => (
            <div onWheel={handleTechScroll}>{menu}</div>
          )}
          filterOption={techsSetting.data as any}
          onBlur={(e: any) => changeTechSearch("")}
          onSearch={(e: any) => changeTechSearch(e)}
          showSearch
          optionFilterProp="children"
          mode="multiple"
        >
          {techsSetting?.data.map((tech) => (
            <>
              {tech.id !== LeaderId && (
                <Option key={tech.id.toString()}>{tech.full_name}</Option>
              )}
            </>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

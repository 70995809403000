import Forbidden from "components/ErrorPages/403";
import { hasPermission } from "components/permissionsGate";
import useNotificationPermission from "hooks/others/useNotificationPermission";
import { useTranslation } from "react-i18next";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { Roles } from "utils/helpers/static_data";

interface protectedRouteProps {
  permissions: any;
  children: any;
  roles?: any[];
  allowedIds?: number[];
}

const ProtectedRoute = (props: protectedRouteProps) => {
  const { t } = useTranslation();
  const roleCanVisit = props.roles?.includes(UserData.Decrypt()?.role_id);
  const idCanVisit = props.allowedIds?.includes(UserData.Decrypt()?.id!);
  const isNotificationEnabled = useNotificationPermission();
  if (
    (!hasPermission(props.permissions) &&
      hasPermission(props.permissions) !== undefined) ||
    (!roleCanVisit && props.roles !== undefined) ||
    (!idCanVisit && props.allowedIds !== undefined)
  ) {
    return <Forbidden />;
  }
  if (
    !isNotificationEnabled &&
    UserData.Decrypt()?.role_id == Roles.technical
  ) {
    return <Forbidden title={t("activate_notifications")} />;
  }
  return props.children;
};

export default ProtectedRoute;

import { Button, Form, Input } from "antd";
import InputRule from "utils/helpers/input-roles";
import { useState } from "react";
import Color from "utils/helpers/export-sass-colors";
import SendOtp from "hooks/query/users/SendOtp";
import CountDown from "components/countDown";
import OtpInput from "react-otp-input";
import ResetPassword from "hooks/query/users/ResetPassword";
import { LoadingOutlined } from "@ant-design/icons";
import { Token } from "utils/helpers/encrypt-decrypt-user";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const OTPNewPassword = ({
  countDown,
  setCountDown,
  setStatus,
  Number,
}: {
  countDown: string;
  setCountDown: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<"number" | "otp">>;
  Number: string;
}) => {
  const [timer, setTimer] = useState("");
  const [Otp, setOtp] = useState("");

  const send = SendOtp();
  const Reset = ResetPassword();
  const navigate = useNavigate();

  const handleSendOTPagain = () => {
    send
      .mutateAsync({ phone_number: Number, signature: "3vNkovJWhT" })
      .then((data: any) => {
        setTimer("");
        setCountDown(data.data.expires_after);
        setStatus("otp");
      });
  };

  const handleNewpassword = (e: any) => {
    Reset.mutateAsync({
      otp: Otp,
      phone_number: Number,
      password: e.password,
      confirm: e.confirm,
    }).then((data: any) => {
      Token.Remove();
      navigate({ pathname: "/login" });
    });
  };

  return (
    <>
      <Form
        onFinish={(e) => handleNewpassword(e)}
        requiredMark={false}
        layout="vertical"
      >
        {timer === "00:00" ? (
          <div>
            <div className="lable">{t("code_expired")}:</div>
            <div
              style={{
                padding: "10px",
                fontSize: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  borderRadius: "30px",
                  color: Color.blue,
                }}
                onClick={() => {
                  handleSendOTPagain();
                }}
                loading={send.isLoading}
              >
                {t("resende_code")}
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="lable">{t("code_expires_in")}:</div>
            <div
              style={{
                padding: "10px",
                fontSize: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!send.isLoading && (
                <CountDown
                  time={countDown}
                  getTimer={(e: any) => setTimer(e)}
                />
              )}
              {send.isLoading && (
                <LoadingOutlined
                  style={{ fontSize: 40, color: Color.blue }}
                  spin={send.isLoading}
                />
              )}
            </div>
          </div>
        )}
        <Form.Item
          name={"otp"}
          rules={[{ required: true, message: t("please_enter_code") }]}
        >
          <OtpInput
            value={Otp}
            onChange={setOtp}
            numInputs={6}
            isInputNum={true}
            containerStyle={{
              justifyContent: "center",
              marginBottom: 20,
              direction: "ltr",
            }}
            className="OTPInput"
            isDisabled={timer === "00:00" || send.isLoading}
            disabledStyle={{
              cursor: "not-allowed",
              opacity: "0.4",
            }}
          />
        </Form.Item>
        <Form.Item
          label={t("new_password")}
          name="password"
          rules={[
            {
              required: true,
              message: t("password_required"),
            },
            ...InputRule.Password,
          ]}
        >
          <Input.Password
            className="passwordInput"
            autoComplete="new-password"
            placeholder={t("new_password")}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={t("confirm_password")}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: t("password_required"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("invalid_confirm_password")));
              },
            }),
          ]}
        >
          <Input.Password
            className="passwordInput"
            autoComplete="new-password"
            placeholder={t("confirm_new_password")}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="submitButton"
            htmlType="submit"
            type="primary"
            loading={Reset.isLoading}
            disabled={timer === "00:00"}
            shape="round"
          >
            {t("confirm")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default OTPNewPassword;

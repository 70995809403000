import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Spin, notification } from "antd";
import { useEffect, useState } from "react";
import useReports from "hooks/query/reports/useReports";
import "jodit/build/jodit.min.css";
import useFormalReport from "hooks/query/reports/useFormalReport";
import UpdateFormalReport from "hooks/query/reports/UpdateFormalReport";
import FormalReportPage from "components/formalReport";
import OldReportModal from "../oldReportsModal";
import { Roles } from "utils/helpers/static_data";
import { useWidth } from "utils/helpers/use-is-mobile";
import format from "utils/helpers/date-format";
import dayjs from "dayjs";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";
import i18next, { t } from "i18next";
import { direction } from "utils/ui/LocaleDirection";
import { isClient } from "utils/helpers/check-roles";

/**
 * show final report details and reports
 */

const FormalReportModal = (props: any) => {
  const [reports] = Form.useForm();
  const Update = UpdateFormalReport();
  const [Content, setContent] = useState<any>("");

  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
  const closeModal = () => {
    props.close();
  };
  const { isMobile } = useWidth();
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);
  const client = User?.role_id === Roles.client;
  const accountant = User?.role_id === Roles.accountant;
  const sales = User?.role_id === Roles.sales;

  const ReportsData = useReports(
    props.id,
    !isClient(UserData.Decrypt()?.role_id!) && !props.dontShowOld
  );
  const FormalReport = useFormalReport(props.id);

  useEffect(() => {
    reports.resetFields();
    if (props.modal && !FormalReport.isLoading) {
      FormalReport.refetch();
    }
    const d: any = document.querySelector(".print");
    if (props.modal) {
      if (d !== null) d.style.display = "block";
    } else {
      if (d !== null) d.style.display = "none";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.modal]);

  useEffect(() => {
    if (FormalReport?.data?.data?.formal_report) {
      setContent(FormalReport?.data?.data?.formal_report);
    }
  }, [FormalReport?.data?.data]);

  const updateReportFinish = () => {
    if (Content === "") {
      notification.open({
        type: "error",
        message:
          i18next.language === "ar"
            ? "يجب تعبئة حقل التقرير النهائي"
            : "Must input final report field",
      });
    } else {
      Update.mutateAsync({
        id: props.id,
        data: {
          formal_report: Content.toString(),
          html_report: document.querySelector(".formalReportPage")?.outerHTML,
        },
      });
    }
  };

  const [OldReportsModalVisiable, setOldReportsModalVisiable] = useState(false);

  const openOldReportModal = () => {
    setOldReportsModalVisiable(true);
  };

  return (
    <Modal
      key={"reportModal" + props.id}
      width={props.dontShowOld ? "70%" : "90%"}
      centered
      onCancel={() => closeModal()}
      open={props.modal}
      footer={[
        <Button
          type="primary"
          onClick={() => closeModal()}
          className="closeBtn"
          key="back"
        >
          {t("close")}
        </Button>,
      ]}
    >
      {!ReportsData.isLoading && !FormalReport.isLoading ? (
        <div className="formalReportModal">
          <Row justify="center" gutter={[20, 20]}>
            <Col lg={props.dontShowOld ? 23 : 16}>
              {!client && !accountant && !sales && !props.dontShowOld && (
                <div
                  style={{
                    display: !isMobile ? "inline-flex" : "block",
                    float: !isMobile ? "right" : "none",
                    width: "fit-content",
                    margin: "0 auto",
                  }}
                >
                  <Button
                    key="add"
                    className="addRwview"
                    onClick={() => updateReportFinish()}
                    type="primary"
                    loading={Update.isLoading}
                  >
                    {i18next.language === "ar"
                      ? " تحديث التقرير العام"
                      : "Update formal report"}
                  </Button>
                </div>
              )}
              <FormalReportPage
                onChange={(content: any) => setContent(content)}
                data={FormalReport?.data?.data}
              />
            </Col>
            {!client && !accountant && !sales && (
              <>
                {!props.dontShowOld && (
                  <Col lg={8}>
                    <Button
                      type="primary"
                      className="oldReportBtn"
                      onClick={() => openOldReportModal()}
                    >
                      {t("old_tichnical_report")}
                    </Button>
                    <OldReportModal
                      modal={OldReportsModalVisiable}
                      close={() => setOldReportsModalVisiable(false)}
                      id={props.id}
                    />

                    <div
                      style={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        minHeight: "70vh",
                        padding: "10px",
                        marginBottom: "1rem",
                      }}
                    >
                      {ReportsData?.data?.data.map(
                        (Report: any, index: any) =>
                          Report.time_in && (
                            <div
                              style={{
                                padding: "30px 20px 25px",
                                marginBottom: 20,
                                position: "relative",
                                height: "auto",
                              }}
                              className="greyBox"
                              key={index}
                            >
                              <Row>
                                <Col span={24} lg={24}>
                                  <div
                                    style={{ cursor: "initial" }}
                                    className="reportUserInfo"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      className="username"
                                    >
                                      <img
                                        src={Report?.checkin_user?.avatar}
                                        alt=""
                                      />
                                      <div>
                                        <div>
                                          {`${
                                            Report?.checkin_user?.full_name !==
                                              undefined &&
                                            Report?.checkin_user?.full_name !==
                                              null &&
                                            Report?.checkin_user?.full_name
                                          }`}
                                        </div>
                                        <span className="data">
                                          {
                                            Report?.checkin_user?.job_title
                                              ?.name
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col span={24} lg={10}>
                                  <div className="labelReport">
                                    {t("checkin")}
                                    <span className="data">
                                      {dayjs(Report?.time_in).format(
                                        format.date_time
                                      )}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={24} lg={10}>
                                  {Report?.time_out && (
                                    <div className="labelReport">
                                      {t("checkout")}
                                      {Report?.time_out && (
                                        <span className="data">
                                          {dayjs(Report?.time_out).format(
                                            format.date_time
                                          )}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Col>
                                <Col span={24} lg={24}>
                                  <div className="labelReport">
                                    {t("technical_report")}
                                    {Report?.content && (
                                      <span className="data">
                                        {Report?.content}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                                <Col span={24} lg={24}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    className="labelReport"
                                  >
                                    {t("total_work_time")} :
                                    {Report?.time && (
                                      <span
                                        style={{ marginRight: 5 }}
                                        className="data"
                                      >
                                        {Report?.time}
                                      </span>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )
                      )}
                    </div>

                    {ReportsData.data && (
                      <div className="time">
                        {t("total_work_time")} :{" "}
                        <span>
                          {ReportsData?.data?.meta?.other?.total_time}
                        </span>
                      </div>
                    )}
                  </Col>
                )}
              </>
            )}
          </Row>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBlock: "10vh",
            minHeight: "70vh",
            alignItems: "center",
          }}
          className="SpinCon"
        >
          <Spin indicator={antIcon} size="large" />
        </div>
      )}
    </Modal>
  );
};

export default FormalReportModal;

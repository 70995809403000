import { useEffect, useState } from "react";

const useKey = (data: any) => {
  const [dataState, setDataState] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      let newData = [];
      let index = 1;
      for (const item of data) {
        const updatedItem = {
          ...item,
          key: index,
        };
        index++;
        newData.push(updatedItem);
      }
      setDataState(newData);
    }
  }, [data]);

  return {
    dataState,
  };
};

export default useKey;

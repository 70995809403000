import { Form, Select } from "antd";
import { Rule } from "antd/es/form";
import useUserPlaces from "hooks/query/places/useUserPlaces";
import { Roles } from "utils/helpers/static_data";
import { useEffect, useState } from "react";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";
import { FormInstance } from "antd/lib/form";
import { t } from "i18next";

const { Option } = Select;
export const CompanyPlaceUser = ({
  name,
  required = false,
  disabled = false,
  rules,
  setCompanyPlace,
  form,
}: {
  name?: string;
  required?: boolean;
  disabled?: boolean;
  rules?: Rule[];
  setCompanyPlace: React.Dispatch<React.SetStateAction<any>>;
  form: FormInstance;
}) => {
  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  const { data } = useUserPlaces(
    User?.role_id === Roles.client && User?.company !== null
  );

  useEffect(() => {
    if (data && name) {
      setCompanyPlace(data.data[0].id.toString());
      form.setFieldValue(name, data.data[0].id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form.Item
      name={name}
      required={required}
      rules={rules}
      label={<>{t("branch")}</>}
    >
      <Select disabled={disabled} onSelect={setCompanyPlace}>
        {data?.data?.map((item) => (
          <Option key={item.id.toString()}>{item.name}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};

import { useInfiniteQuery } from "react-query";
import { FetchUsersCompany } from "services/companies";
import ApiResult from "utils/api/models/api-result";
import { ApiError } from "utils/api/models/api-error";
import { UserModel } from "models/users";

export default function useUsersCompany(
  id: any,
  page: number,
  perPage: any,
  enabled: boolean = true
) {
  const queryKey = ["usersCompanies", id, perPage];
  return {
    ...useInfiniteQuery<ApiResult<UserModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) =>
        await FetchUsersCompany(id, pageParam, perPage),
      {
        refetchOnWindowFocus: false,
        staleTime: 15000,
        cacheTime: 15000,
        enabled: enabled,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}

import { FetchOldReports } from "services/reports/index";
import { useQuery } from "react-query";
import ApiResult from "utils/api/models/api-result";
import { ReportModel } from "models/reports";
import { ApiError } from "utils/api/models/api-error";

export default function useOldReports(id: number, show: any) {
  const queryKey = ["OldReports", { id, show }];
  return {
    ...useQuery<ApiResult<ReportModel[]>, ApiError>(
      queryKey,
      () => FetchOldReports(id, show),
      {
        staleTime: 10000,
      }
    ),
  };
}

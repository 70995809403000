import { hasPermission } from "components/permissionsGate/index";
import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class CategoriesService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async FetchSelector(): Promise<ApiResponse<any>> {
    return this.get("/api/categories?items_per_page=100&index_type=selector");
  }
  public async FetchList(): Promise<ApiResponse<any>> {
    return this.get("/api/categories?items_per_page=100&index_type=list");
  }

  public async FetchServices(
    page: number,
    perPage: number,
    id: number,
    search: string,
    index_type: string
  ): Promise<ApiResponse<any>> {
    return this.get(
      `/api/get-services/category/${id}?page=${page}&items_per_page=${perPage}${
        search !== "" ? `&filter[name]=${search}` : ""
      }${index_type !== "" ? "&index_type=" + index_type : ""}`
    );
  }
  public async AttachCategoryToTech(
    id: number,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.post(`/api/attachCategoryToTechnical/${id}`, data);
  }
  public async EditCategory(id: number, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/categories/${id}`, data);
  }
  public async AddCategory(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/categories`, data);
  }
  public async DeleteCategory(id: number): Promise<ApiResponse<any>> {
    return this.delete(`/api/categories/${id}`);
  }
}

const categoriesService = new CategoriesService();

export const FetchCategoriesSelector = async () => {
  if (hasPermission(["browse_categories"])) {
    const result: any = await categoriesService.FetchSelector();
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchCategoriesList = async () => {
  if (hasPermission(["browse_categories"])) {
    const result: any = await categoriesService.FetchList();
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const AttachCategoryToTech = async (id: number, data: any) => {
  const result: any = await categoriesService.AttachCategoryToTech(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const EditCategoryAsync = async ({
  data,
  id,
}: {
  id: number;
  data: any;
}) => {
  const result: any = await categoriesService.EditCategory(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const AddCategoryAsync = async ({ data }: { data: any }) => {
  const result: any = await categoriesService.AddCategory(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const DeleteCategoryAsync = async ({ id }: { id: number }) => {
  const result: any = await categoriesService.DeleteCategory(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const FetchServices = async (
  page: number,
  perPage: number,
  id: number,
  search: string
) => {
  if (id !== 0 && hasPermission(["browse_categories"])) {
    const result: any = await categoriesService.FetchServices(
      page,
      perPage,
      id,
      search,
      ""
    );
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchServicesList = async (
  page: number,
  perPage: number,
  id: number,
  search: string
) => {
  if (id !== 0 && hasPermission(["browse_categories"])) {
    const result: any = await categoriesService.FetchServices(
      page,
      perPage,
      id,
      search,
      "list"
    );
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

import { DownOutlined } from "@ant-design/icons";
import "./style.css";

const ExpandIcon = (props: any) => {
  return (
    <DownOutlined
      onClick={props.onClick}
      style={{
        transform: props.isExpanded ? "rotate(180deg)" : "",
        transition: "0.4s",
        color: "black",
        cursor: "pointer",
      }}
    />
  );
};

export default ExpandIcon;

import { Form, Input } from "antd";
import Modal from "antd/es/modal/Modal";
import { t } from "i18next";
import React, { SetStateAction } from "react";

const AddClassificationModal = ({
  open,
  setOpen,
  isLoading,
  onAdd,
}: {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  onAdd: ({ data }: any) => void;
}) => {
  const [form] = Form.useForm();
  const handleClose = () => {
    form.setFieldsValue("");
    setOpen(false);
  };
  const onFinish = (e: any) => {
    const formData = new FormData();

    formData.append("name_ar", e.name_ar);
    formData.append("name_en", e.name_en);

    onAdd({ data: formData });
  };
  return (
    <Modal
      key={"addCategory"}
      title={t("add_category")}
      open={open}
      width={400}
      centered
      bodyStyle={{ padding: 20 }}
      destroyOnClose
      onCancel={() => handleClose()}
      confirmLoading={isLoading}
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{}}
        labelCol={{ span: 24 }}
        validateMessages={{
          required: t("please_input") + " ${label}!",
        }}
      >
        <Form.Item
          name={"name_ar"}
          label={t("arabic_name")}
          rules={[{ required: true }]}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={"name_en"}
          label={t("english_name")}
          rules={[{ required: true }]}
          required
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddClassificationModal;

import { AimOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import Color from "utils/helpers/export-sass-colors";
import ToolTip from "components/tooltip";
import { TicketModel } from "models/tickets";
import usePlacesSibling from "hooks/query/places/usePlacesSibling";
import MapWrapper from "components/googleMap/MapWrapper";
import Marker from "components/googleMap/Marker";
import { t } from "i18next";

export const Location = ({
  name,
  form,
  required = false,
  disabled = false,
  ticket,
  companyPlace,
}: {
  name: { location: string; lat: string; lon: string };
  form: FormInstance;
  required?: boolean;
  disabled?: boolean;
  ticket?: TicketModel;
  companyPlace: any;
}) => {
  const { data: Places } = usePlacesSibling(companyPlace);

  const [InitalLocation, setInitalLocation] = useState<{
    lat?: number;
    lng?: number;
  }>({
    lat: undefined,
    lng: undefined,
  });
  const [LoacitionModalVisible, setLoacitionModalVisible] =
    useState<boolean>(false);

  const setLoc = (
    { lat, lng }: { lat?: number; lng?: number },
    validate: boolean = true
  ) => {
    setInitalLocation({
      lat,
      lng,
    });
    form.setFieldValue(name.lat, lat);
    form.setFieldValue(name.lon, lng);
    if (validate) {
      form.validateFields([name.lat, name.lon, name.location]);
    }
  };

  useEffect(() => {
    const currentPlaceLocation = Places?.data?.places.find(
      (place) => place.id === parseInt(companyPlace)
    );
    if (currentPlaceLocation && !ticket) {
      setLoc({
        lat: currentPlaceLocation.location.lat,
        lng: currentPlaceLocation.location.lon,
      });
    } else if (companyPlace === "0") {
      setLoc({ lat: undefined, lng: undefined }, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Places?.data?.places, companyPlace]);

  useEffect(() => {
    if (ticket) {
      setLoc({ lat: ticket.lat, lng: ticket.lon });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  return (
    <>
      <Form.Item hidden name={name.lat} />
      <Form.Item hidden name={name.lon} />
      <Form.Item
        label={
          <ToolTip content={t("can_write_address")}>{t("location")}</ToolTip>
        }
        name={name.location}
        required={required}
        rules={
          required
            ? [
                {
                  validator(rule, value, callback) {
                    if (
                      InitalLocation?.lat === undefined &&
                      InitalLocation.lng === undefined
                    )
                      return Promise.reject(
                        new Error(t("please_set_location_on_map"))
                      );
                    return Promise.resolve();
                  },
                },
              ]
            : undefined
        }
      >
        <Input
          disabled={disabled}
          placeholder={
            InitalLocation?.lat && InitalLocation?.lng
              ? `${InitalLocation?.lat} , ${InitalLocation?.lng}`
              : undefined
          }
          className="locationInput"
          autoComplete="off"
          prefix={
            <button
              type="button"
              onClick={() => setLoacitionModalVisible(true)}
              className="locationBtn"
              style={{
                backgroundColor:
                  InitalLocation?.lat && InitalLocation?.lng ? Color.green : "",
              }}
            >
              <AimOutlined />
            </button>
          }
        />
      </Form.Item>
      <Modal
        width={700}
        onCancel={() => setLoacitionModalVisible(false)}
        title={t("location")}
        open={LoacitionModalVisible}
        centered
        footer={[
          <>
            {!disabled ? (
              <Button
                key="add"
                className="addRwview"
                type="primary"
                onClick={() => setLoacitionModalVisible(false)}
              >
                {t("add_location")}
              </Button>
            ) : null}
          </>,
        ]}
      >
        <MapWrapper
          disabled={disabled}
          onClick={(e) => {
            setLoc({ lat: e.latLng?.lat()!, lng: e.latLng?.lng()! });
          }}
          allowCopy
          center={
            ticket
              ? {
                  lat: ticket?.lat,
                  lng: ticket?.lon,
                }
              : {
                  lat: 33.5152268,
                  lng: 36.2751301,
                }
          }
          initialMarker={{ lat: InitalLocation.lat!, lng: InitalLocation.lng! }}
        >
          {Places?.data?.places.map((Place) => (
            <Marker
              key={Place.id}
              card={
                <div className="modalMap">
                  <div
                    style={{
                      direction: "rtl",
                      textAlign: "right",
                      marginBottom: 20,
                    }}
                    className="timeLabel"
                  >
                    {t("branch")}
                    <span style={{ color: Color.gray8 }}>{Place.name}</span>
                  </div>
                  <div
                    style={{ direction: "rtl", textAlign: "right" }}
                    className="timeLabel"
                  >
                    {t("address")} :
                    <span
                      style={{
                        color: Color.gray8,
                        display: "block",
                        marginTop: 5,
                        marginRight: 0,
                      }}
                    >
                      {Place.address}
                    </span>
                  </div>
                </div>
              }
              position={{
                lat: Place?.location.lat,
                lng: Place?.location.lon,
              }}
              active={Place.id === parseInt(companyPlace)}
              onClick={(e) => {
                setLoc({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
              }}
            />
          ))}
        </MapWrapper>
      </Modal>
    </>
  );
};

import { Spin } from "antd";

const ModalOverlay = ({ isLoading, isFetching }: any) => {
  const spinner = <Spin spinning={isLoading || isFetching}></Spin>;
  return (
    isLoading ||
    (isFetching && (
      <div className="modal-overlay">
        <div className="overlay-content">{spinner}</div>
      </div>
    ))
  );
};

export default ModalOverlay;

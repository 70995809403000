import { Navigate, Route } from "react-router-dom";
import Login from "pages/auth/login";
import Forget from "pages/auth/forget";
import Register from "pages/auth/register";
import { Routes } from "react-router-dom";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forget" element={<Forget />} />
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AuthRoutes;

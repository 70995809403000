import { UnratedTicketModel } from "models/tickets";
import { useQuery, useQueryClient } from "react-query";
import { UnratedTickets } from "services/tickets";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";

export default function useUnratedTicket(userId: number, enabled: boolean) {
  const queryKey = ["unratedTicket", userId, enabled];
  const queryClient = useQueryClient();
  return {
    ...useQuery<ApiResult<UnratedTicketModel>, ApiError>(
      queryKey,
      () => UnratedTickets({ userId }),
      {
        cacheTime: 0,
        staleTime: 0,
        enabled: enabled,
      }
    ),
  };
}

import { LevelModel } from "models/levels";
import { useQuery } from "react-query";
import { FetchLevels } from "services/levels";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";

export default function useLevels(page: number, perPage: number) {
  const queryKey = ["levels", page, perPage];
  return {
    ...useQuery<ApiResult<Array<LevelModel>>, ApiError>(
      queryKey,
      async () => await FetchLevels(page, perPage),
      {
        refetchOnWindowFocus: false,
        staleTime: 10000,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
  // }
}

import { Col, Form, FormInstance, Input, Radio, Row } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import React, { useEffect, SetStateAction } from "react";
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

type Props = {
  form: FormInstance<any>;
};

function TicketsDateInput({ form }: Props) {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <Row justify="center" gutter={[20, 0]} style={{ marginTop: "1rem" }}>
        <Col span={24} lg={12}>
          <Form.Item
            label={<>{t("start")}</>}
            name={"contract_start_date"}
            initialValue={dayjs().format("YYYY-MM-DD")}
            rules={[
              { required: true },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    dayjs(value).isAfter(
                      dayjs(form.getFieldValue("contract_end_date"))
                    )
                  ) {
                    return Promise.reject(t("start_date_before_end_date"));
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (value && dayjs(value).isBefore(dayjs(), "day")) {
                    return Promise.reject(t("start_date_not_in_the_past"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input type={"date"} autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={24} lg={12}>
          <Form.Item
            label={<>{t("finish")}</>}
            name={"contract_end_date"}
            initialValue={dayjs().add(1, "year").format("YYYY-MM-DD")}
            rules={[
              {
                required: true,
              },
              {
                validator: (_, value) => {
                  if (
                    value &&
                    dayjs(value).isBefore(
                      dayjs(form.getFieldValue("contract_start_date"))
                    )
                  ) {
                    return Promise.reject(t("end_date_after_start_date"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input type={"date"} autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={<>{t("visits_count")}</>}
        name={"visits_number"}
        initialValue={24}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input type={"number"} autoComplete="off" />
      </Form.Item>
    </div>
  );
}

export default TicketsDateInput;

import { useEffect, useRef, useState } from "react";

const CountDown = (props: any) => {
  const Ref = useRef<any>(null);

  const [timer, setTimer] = useState<any>("");

  const count = (e: string) => {
    let m = Math.floor(parseInt(e) / 60).toString();
    if (m.length === 1) {
      m = "0" + m;
    } else {
      // eslint-disable-next-line no-self-assign
      m = m;
    }
    let s = (parseInt(e) % 60).toString();
    if (s.length === 1) {
      s = "0" + s;
    } else {
      // eslint-disable-next-line no-self-assign
      s = s;
    }
    let time = (m === "NaN" ? "00" : m) + ":" + (s === "NaN" ? "00" : s);
    return time;
  };

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };
  const startTimer = (e: any) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e: any) => {
    setTimer(count(props.time));

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(
      deadline.getSeconds() + parseInt(props.time ? props.time : 0)
    );
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {timer}
      {props.getTimer?.(timer)}
    </>
  );
};

export default CountDown;

import { useAppDispatch } from "store";
import { useMutation } from "react-query";
import { isError } from "utils/api/models/api-response";
import { checkAuth } from "state";
import { REGISTER_FAIL, REGISTER_SUCCESS } from "actions/types";
import axios from "axios";
import { ApiErrorNotification } from "utils/ui/notificationService";

const register = async (data: {
  full_name: string;
  phone_number: string;
  job_title_id: string;
  password: string;
  password_confirmation: string;
  signature: string;
}) => {
  const URL = `${process.env.REACT_APP_API_URL}api/register`;
  return await axios
    .post(URL, data, { headers: { "Accept-Language": "ar" } })
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        ApiErrorNotification(error.response.data);
        throw Error(REGISTER_FAIL);
      }
    );
};

export default function useRegister() {
  const dispatch = useAppDispatch();
  return {
    ...useMutation(register, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          dispatch(
            checkAuth({
              type: REGISTER_SUCCESS,
            })
          );
        }
      },
      onError: async (err: Error) => {
        dispatch(checkAuth({ type: err.message }));
      },
    }),
  };
}

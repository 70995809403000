import { useQueryClient } from "react-query";
import { PostTicket } from "services/tickets";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export default function AddTicket() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(PostTicket, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message: t("the_ticket_has_been_created_successfully"),
            type: "success",
          });
          queryClient.invalidateQueries("tickets");
          queryClient.invalidateQueries("clientRequest");
        }
      },
    }),
  };
}

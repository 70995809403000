import React from "react";
import { HashRouter } from "react-router-dom";
import Layout from "containers/Layout";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import AuthRoutes from "./auth.routes";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import SidebarLayout from "containers/SideBarLayout";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const loading = (
  <div className="loadingCon">
    <Spin indicator={antIcon} size="large" />
  </div>
);

const IndexRoutes = () => {
  const user = UserData.Decrypt();

  return (
    <React.Suspense fallback={loading}>
      {!user && <AuthRoutes />}
      {user && <SidebarLayout />}
    </React.Suspense>
  );
};

export default IndexRoutes;

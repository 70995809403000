import Table, { ColumnType } from "antd/es/table";
import { CategoryModel } from "models/categories";
import { UserModel } from "models/users";
import { useEffect, useState } from "react";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { Roles } from "utils/helpers/static_data";

import AddCategoryModal from "components/Modals/categoriesModals/addCategoryModal";
import EditCategoryModal from "components/Modals/categoriesModals/editCategoryModal";
import { Button, Col, Popconfirm, Row } from "antd";
import DeleteCategory from "hooks/query/categories/deleteCategory";
import useCategoriesSelector from "hooks/query/categories/useCategories";
import { useWidth } from "utils/helpers/use-is-mobile";
import Color from "utils/helpers/export-sass-colors";
import { hasPermission } from "components/permissionsGate";
import i18next, { t } from "i18next";

const CPCategories = () => {
  const { data, isLoading, isFetching } = useCategoriesSelector();
  const deleteCategory = DeleteCategory();

  const { isMobile } = useWidth();

  const [User, setUser] = useState<UserModel | null>(null);
  const columns: ColumnType<CategoryModel>[] = [
    {
      title: t("arabic_name"),
      dataIndex: "name_ar" ?? "name",
      align: "center",
      width: "20%",
    },
    {
      title: t("english_name"),
      dataIndex: "name_en",
      align: "center",
      width: "25%",
    },

    {
      title: "",
      dataIndex: "id",
      align: "center",
      width: 200,
      render(value, record) {
        return (
          <div>
            {hasPermission(["edit_categories"]) && (
              <EditCategoryModal item={record} id={value} />
            )}
            {hasPermission(["delete_categories"]) && (
              <Popconfirm
                okType="danger"
                title={
                  i18next.language === "ar"
                    ? "هل انت متأكد من الحذف؟"
                    : "Are you sure to delete?"
                }
                onConfirm={() => {
                  return new Promise((resolve, reject) => {
                    deleteCategory
                      .mutateAsync({ id: value })
                      .then(() => {
                        resolve(null);
                      })
                      .catch(() => {
                        reject();
                      });
                  });
                }}
              >
                <Button danger type="link">
                  {t("delete")}
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const deviceColumns: ColumnType<CategoryModel>[] = [
    {
      title: "",
      render: (obj: CategoryModel) => {
        return (
          <div
            style={{
              padding: "0",
              borderRadius: "18px",
              overflow: "hidden",
              border: `2px solid ${Color.blue}`,
              width: "90%",
              margin: "auto",
            }}
          >
            <Row style={{ padding: "20px" }}>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span>{t("arabic_name")}: </span>
                  {obj.name_ar}
                </div>
              </Col>
              <Col md={12} span={24}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <span>{t("english")}: </span>
                  {obj.name_en}
                </div>
              </Col>

              <Col md={12} span={24}>
                {hasPermission(["edit_categories"]) && (
                  <EditCategoryModal item={obj} id={obj.id} />
                )}
                {hasPermission(["delete_categories"]) && (
                  <Popconfirm
                    okType="danger"
                    title={
                      i18next.language === "ar"
                        ? "هل انت متأكد من الحذف؟"
                        : "Are you sure to delete?"
                    }
                    onConfirm={() => {
                      return new Promise((resolve, reject) => {
                        deleteCategory
                          .mutateAsync({ id: obj.id })
                          .then(() => {
                            resolve(null);
                          })
                          .catch(() => {
                            reject();
                          });
                      });
                    }}
                  >
                    <Button danger type="link">
                      {t("delete")}
                    </Button>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  return (
    <div className="categories">
      <div style={{ textAlign: "end", marginBottom: "1rem" }}>
        <AddCategoryModal />
      </div>
      <div>
        <Table
          bordered
          columns={isMobile ? deviceColumns : columns}
          dataSource={data?.data}
          loading={isLoading || isFetching}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default CPCategories;

import { FC, useState, createContext, ReactNode, useEffect } from "react";
import { useWidth } from "utils/helpers/use-is-mobile";

type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

interface SidebarProviderProps {
  children: ReactNode;
}

export const SidebarProvider: FC<SidebarProviderProps> = ({ children }) => {
  const { isMobile } = useWidth();
  const [sidebarToggle, setSidebarToggle] = useState(isMobile ? false : true);

  useEffect(() => {
    setSidebarToggle(isMobile ? false : true);
  }, [isMobile]);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { store } from "./store";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { ReactQueryDevtools } from "react-query/devtools";
import { ErrorBoundary } from "react-error-boundary";
import InternalError from "components/ErrorPages/500";
import { HashRouter } from "react-router-dom";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18next from "i18next";
import translationEn from "./translations/en.json";
import translationAr from "./translations/ar.json";
import { SidebarProvider } from "state/SideBarContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
  maxAge: 1000 * 60 * 60 * 24,
  hydrateOptions: {},
});

i18next.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    ar: { translation: translationAr },
  },
  lng: "ar",
  fallbackLng: "ar",
  interpolation: { escapeValue: false },
});

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <ErrorBoundary
        FallbackComponent={(error) => <InternalError error={error} />}
      >
        <Provider store={store}>
        <SidebarProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            {/* <ContextMenu /> */}
            <div>
              <ReactQueryDevtools />
            </div>
          </QueryClientProvider>
          </SidebarProvider>
        </Provider>
      </ErrorBoundary>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

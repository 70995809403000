import { Select, Input, Radio, Switch, ConfigProvider, DatePicker } from "antd";
import React from "react";
import { useWidth } from "utils/helpers/use-is-mobile";
import format from "utils/helpers/date-format";
import dayjs from "dayjs";
import { t } from "i18next";

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

interface option {
  name: string;
  value: any;
}

interface elementConfig {
  onChange: (e: any) => void;
  value?: any;
  suffixIcon?: React.ReactNode;
  placeholder?: string;
  allowClear?: boolean;
  defaultValue?: any;
  checked?: boolean;
  defaultChecked?: boolean;
  onClick?: () => void;
}

export interface TableFiltersProps {
  elementType: string;
  elementConfig: elementConfig;
  label: string;
  options?: option[];
}

const TableFilters = (props: TableFiltersProps) => {
  const { isMobile } = useWidth();

  let element = <></>;

  const divStyle = {
    flexBasis: isMobile ? "100%" : "",
    width: isMobile ? "100%" : "auto",
  };

  switch (props.elementType) {
    case "select":
      element = (
        <div style={divStyle}>
          <label className="filter-label">{props.label}</label>
          <Select
            {...props.elementConfig}
            style={{ width: isMobile ? "100%" : "170px" }}
          >
            {props.options?.map((option) => (
              <Option key={option.value}>{option.name}</Option>
            ))}
          </Select>
        </div>
      );
      break;
    case "search":
      element = (
        <div style={divStyle}>
          <label className="filter-label">{props.label}</label>
          <Search
            style={{ width: isMobile ? "100%" : "270px" }}
            {...props.elementConfig}
          />
        </div>
      );
      break;
    case "radio":
      element = (
        <div style={divStyle}>
          <label className="filter-label">{props.label}</label>
          <Radio.Group
            style={{ width: isMobile ? "100%" : "" }}
            {...props.elementConfig}
          >
            {props.options?.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      );
      break;
    case "switch":
      element = (
        <div style={divStyle}>
          <label className="filter-label">{props.label}</label>
          <Switch
            defaultChecked={props.elementConfig.defaultChecked}
            checked={props.elementConfig.checked}
            onChange={props.elementConfig.onChange}
            onClick={props.elementConfig.onClick}
          />
        </div>
      );
      break;
    case "date_range":
      element = (
        <div style={divStyle}>
          <label className="filter-label">{props.label}</label>
          <ConfigProvider direction="ltr">
            <RangePicker
              style={{ margin: 0 }}
              value={props.elementConfig.value}
              onChange={props.elementConfig.onChange}
              placeholder={[t("start_date"), t("end_date")]}
              disabledDate={(current) => {
                return (
                  current.isBefore(dayjs("2022-01-01")) ||
                  current.isAfter(dayjs().subtract(-1, "day"))
                );
              }}
              format={format.date}
              size="small"
            />
          </ConfigProvider>
        </div>
      );
  }
  return element;
};

export default TableFilters;

import { LoadingOutlined } from "@ant-design/icons";
import { Button, Pagination, Popconfirm, Spin, Table, Tooltip } from "antd";
import Modal from "antd/es/modal/Modal";
import { ColumnsType } from "antd/es/table";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "components/SkeletonsLoading/SkeletonTable";
import TicketFillters from "components/filters/ticketsFillters";
import ExpandableTable from "components/table/expandableTable";
import useKey from "hooks/others/useKey";
import useClientRequest from "hooks/query/clientRequests/useClientRequest";
import useUsersClientRequests from "hooks/query/clientRequests/useUsersClientRequests";
import TransferTicket from "hooks/query/tickets/TransferTicket";
import i18next, { t } from "i18next";
import { ClientRequestModel } from "models/clientRequests";
import { useEffect, useState } from "react";
import Color from "utils/helpers/export-sass-colors";

interface TransferModalProps {
  open: boolean;
  setOpen: any;
  userId: number;
  ticketId: number;
  crId: number;
}
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const TicketTransferModal = ({
  open,
  setOpen,
  userId,
  ticketId,
  crId,
}: TransferModalProps) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [selectedCR, setSelectedCR] = useState<any>();
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [enabled, setEnabled] = useState<boolean>(false);

  const [expandedTicketId, setExpandedTicketId] = useState("");

  const [ticketsPage, setTicketsPage] = useState({
    page: 1,
    perPage: 3,
  });

  const { data, isLoading, isFetching } = useUsersClientRequests(
    userId,
    page,
    search
  );

  const {
    data: tickets,
    isLoading: ticketsLoading,
    isFetching: ticketsFetching,
  } = useClientRequest(
    +expandedTicketId,
    ticketsPage.page,
    "0",
    "-1",
    "",
    "",
    "",
    0,
    "",
    "0",
    0,
    enabled
  );

  const transfer = TransferTicket();

  const { dataState: clientRequests } = useKey(data?.data);

  const columns: ColumnsType<ClientRequestModel> = [
    {
      title: t("client_request_id"),
      dataIndex: "id",
      align: "center",
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",
      render: (status: any) => (
        <div
          style={{ width: "120px", textTransform: "capitalize" }}
          className={`statusBtn status${status.id}`}
        >
          {status.status}
        </div>
      ),
    },
    Table.EXPAND_COLUMN,
  ];

  const ticketsColumns: ColumnsType<ClientRequestModel> = [
    {
      title: t("ticket_id"),
      dataIndex: "id",
      align: "center",
      render: (id, obj: ClientRequestModel) => (
        <Tooltip placement="left" color="#fff" title={obj.description}>
          {id}
        </Tooltip>
      ),
    },
    {
      title: t("service"),
      dataIndex: "service",
      align: "center",
      render: (service) => (
        <span style={{ fontWeight: "bold" }}>{service?.name}</span>
      ),
    },
    {
      title: t("status"),
      dataIndex: "ticket_status",
      align: "center",
      render: (ticket_status: any) => (
        <div
          style={{ width: "120px", textTransform: "capitalize" }}
          className={`statusBtn status${ticket_status.id}`}
        >
          {ticket_status.status}
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    setSelectedCR(crId);
  }, [crId]);

  const handleOk = () => {
    const data = {
      client_request_id: selectedCR,
    };
    transfer.mutateAsync({ id: ticketId, data }).then(() => {
      setOpen(false);
    });
  };

  useEffect(() => {
    setPage(1);
  }, [open]);

  const expandedRowRender = () => {
    return (
      <>
        <Table
          size="small"
          pagination={false}
          dataSource={tickets?.data}
          columns={ticketsColumns}
          loading={ticketsLoading || ticketsFetching}
          bordered
        />
        <Pagination
          showSizeChanger={false}
          pageSize={8}
          onChange={(e) =>
            setTicketsPage({
              perPage: 3,
              page: e,
            })
          }
          current={tickets?.meta.currentPage}
          total={tickets?.meta.total}
          size="small"
        />
      </>
    );
  };

  const titleConfirmation = (
    <p>
      {i18next.language === "ar"
        ? "هل انت متأكد من نقل التذكرة من الطلب"
        : "Are you sure you want to transfer ticket from request"}{" "}
      <span style={{ color: Color.blue_2 }}>{crId}</span>{" "}
      {i18next.language === "ar" ? "إلى الطلب" : "to request"}{" "}
      <span style={{ color: Color.blue_2 }}>{selectedCR}</span> ؟
    </p>
  );

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={t("trasfate_ticket")}
      centered
      width={800}
      bodyStyle={{ maxHeight: "650px", overflowY: "scroll" }}
      footer={[
        <Button onClick={() => setOpen(false)}>{t("cancel")}</Button>,
        <Popconfirm title={titleConfirmation} onConfirm={handleOk}>
          <Button loading={transfer.isLoading} type="primary">
            {t("confirm")}
          </Button>
        </Popconfirm>,
      ]}
    >
      {!isLoading ? (
        <div className="tickets">
          <>
            <TicketFillters SearchText={search} setSearch={setSearch} />
            <SkeletonTable
              loading={isLoading || isFetching}
              columns={columns as SkeletonTableColumnsType[]}
              rowCount={3}
            >
              <ExpandableTable
                columns={columns}
                data={clientRequests}
                expandedRowRender={expandedRowRender}
                setExpandedRowKey={setExpandedRowKey}
                expandedRowKeys={[expandedRowKey]}
                isLoading={isLoading}
                multi={false}
                setId={setExpandedTicketId}
                setEnabled={setEnabled}
                rowSelection={{
                  type: "radio",
                  columnWidth: 48,
                  onChange: (key: any, selectedRow: any) => {
                    setSelectedCR(selectedRow[0].id);
                  },
                  selectedRowKeys: [selectedCR],
                }}
                rowKey="id"
              />
            </SkeletonTable>
            <Pagination
              showSizeChanger={false}
              pageSize={5}
              onChange={(e) => {
                setPage(e);
              }}
              current={page}
              total={data?.meta.total}
            />
          </>
        </div>
      ) : (
        <div
          className="SpinCon"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin indicator={antIcon} size="large" />
        </div>
      )}
    </Modal>
  );
};

export default TicketTransferModal;

import { useQueryClient } from "react-query";
import { UpdateTicketF } from "services/tickets";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { UpdateLevelService } from "services/levels";
import i18next from "i18next";

export default function UpdateLevel() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(UpdateLevelService, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم تحديث المستوى"
                : "Level updated successfully",
          });
          queryClient.invalidateQueries("levels");
        }
      },
    }),
  };
}

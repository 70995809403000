import { AxiosRequestConfig } from "axios";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class ContactsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(id: any): Promise<ApiResponse<any>> {
    return this.get(`/api/company-places/${id}/contacts?sort=created_at`);
  }
  public async FetchAll(id: any): Promise<ApiResponse<any>> {
    return this.get(`/api/company-places/${id}/contacts/full-list`);
  }
  public async FetchSingle(id: any, placeId: any): Promise<ApiResponse<any>> {
    return this.get(`/api/companies/${id}/company-places/${placeId}`);
  }

  public async add(id: any, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/company-places/${id}/contacts`, data);
  }
  public async edit(
    id: any,
    placeId: any,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.put(`/api/companies/${id}/company-places/${placeId}`, data);
  }
  public async delete(id: any, placeId: any): Promise<ApiResponse<any>> {
    return super.delete(`/api/companies/${id}/company-places/${placeId}`);
  }
}

const contactsService = new ContactsService();

export const FetchContacts = async (id: any) => {
  if (id.toString() !== "0") {
    const result: any = await contactsService.Fetch(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchAllContacts = async (id: any) => {
  if (id && id !== "0") {
    const result: any = await contactsService.FetchAll(id);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};
export const FetchCompanyPlace = async (id: any, placeId: any) => {
  if (placeId !== "0") {
    const result: any = await contactsService.FetchSingle(id, placeId);
    if (isError(result)) {
      ApiErrorNotification(result);
      throw result;
    } else {
      return result;
    }
  }
};

export const AddContactAsync = async ({ id, data }: any) => {
  const result: any = await contactsService.add(id, data);
  if (isError(result)) {
    // ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const EditCompanyPlaceAsync = async ({ id, placeId, data }: any) => {
  const result: any = await contactsService.edit(id, placeId, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const DeleteCompanyPlaceAsync = async ({ id, placeId }: any) => {
  const result: any = await contactsService.delete(id, placeId);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
